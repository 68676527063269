/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull shakira'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 25
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/skipify/Checkout/implementation/shakira)
 */

import * as amplitude from "@amplitude/analytics-browser";

export type Environment = "local" | "production" | "staging" | "development";

export const ApiKey: Record<Environment, string> = {
  local: "",
  production: "",
  staging: "",
  development: "",
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: "25",
    branch: "main",
    source: "shakira",
    versionId: "1aee22ec-a5a3-422d-979c-2a9c0be39dcb",
  },
  ...{
    ingestionMetadata: {
      sourceName: "browser-typescript-ampli",
      sourceVersion: "2.0.0",
    },
  },
};

export interface LoadOptionsBase {
  disabled?: boolean;
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment;
  client?: { configuration?: BrowserOptions };
};
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * List of experimental feature toggles received by the user for population splitting in analysis.
   *
   * | Rule | Value |
   * |---|---|
   * | Unique Items | false |
   * | Item Type | string |
   */
  Experiments?: string[];
  initial_referring_domain?: string;
  merchant_id?: any;
  merchant_industry?: any;
  merchant_name?: any;
  parent_merchant_name?: string;
  referring_domain?: string;
}

export interface FeAddManualPaymentProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeAuthProperties {
  auth_method: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * For events with a success/fail condition this property indicates which outcome occurred
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Success, Fail, Canceled |
   */
  status: "Success" | "Fail" | "Canceled";
}

export interface FeAuthChallengeRequestedProperties {
  auth_method: string;
}

export interface FeCcpaNotificationSeenRightToKnowProperties {
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
}

export interface FeCheckoutAsGuestProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
}

export interface FeCheckoutExitedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
}

export interface FeCheckoutInitiatedProperties {
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  merchant_id?: any;
  merchant_industry?: any;
  merchant_name?: any;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_id?: string;
  parent_merchant_name?: any;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * Total of order plus taxes, shipping, fees, etc.
   *
   * Property comes in as an integer. Needs to be converted to a decimal number.  x/100 Needs to be converted to a decimal number. x/100
   */
  total?: any;
}

export interface FeClClickedCardProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_issuer_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_issuer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_partner_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_network_type?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeClCompleteCardFailProperties {
  /**
   * Card Network - Amex, Visa, Mastercard, Discover etc
   *
   *
   * can be merged with card \_network type property
   */
  card_brand?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_issuer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  error_code?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
}

export interface FeClCompleteCardRequestedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_issuer_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_issuer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_partner_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_network_type?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeClCompleteCardSuccessProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_issuer_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_issuer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_partner_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_network_type?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeClConfirmPanFailProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_issuer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
}

export interface FeClConfirmPanRequestedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_issuer_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_issuer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_network_type?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeClConfirmPanSuccessProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_issuer_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_issuer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_partner_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_network_type?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeClIssuerChallengeExitedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_issuer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeClIssuerChallengeFailProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_issuer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeClIssuerChallengeReviewpayPageProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_issuer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeClIssuerChallengeSelectpaymentPageProperties {
  /**
   * issuer_dba
   *
   *
   * * American Express (“Amex”)
   *
   * * Bank of America
   *
   * * Citi
   *
   * * Chase
   *
   * * Discover
   *
   * * Synchrony
   *
   * * Wells Fargo
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_issuer_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_issuer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_partner_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_network_type?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeClIssuerChallengeSuccessProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_issuer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeClMetadataRequestFailProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_issuer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeClMetadataRequestSuccessProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  card_issuer_name?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_issuer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  card_linking_partner_id?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  card_network_type?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeClMetadataRequestedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_issuer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeClReviewpaywalletPageProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amex_cl_options_count?: number;
  /**
   * Count of linked amex cards available for users to select from on the wallet screen.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amex_cl_stored_count?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeClSelectpaymentPageProperties {
  /**
   * Count of not yet linked amex cards available for users to select from on the select card or wallet screen.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  amex_cl_options_count: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_issuer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeConsentToUnenrollProperties {
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
}

export interface FeContactSupportSubmittedProperties {
  customer_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * For events with a success/fail condition this property indicates which outcome occurred
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Success, Fail, Canceled |
   */
  status: "Success" | "Fail" | "Canceled";
  subject: string;
}

export interface FeCreateAddressProperties {
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
}

export interface FeCreatePaymentMethodProperties {
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
}

export interface FeCustomerAccountCreatedProperties {
  /**
   * Device ID of the user.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  merchant_industry?: any;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
}

export interface FeCustomerDeviceNotRecognizedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  merchant_id?: any;
  merchant_industry?: any;
  merchant_name?: any;
  parent_merchant_name?: any;
}

export interface FeCustomerDeviceRecognizedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  merchant_id?: any;
  merchant_industry?: any;
  merchant_name?: any;
  parent_merchant_name?: any;
}

export interface FeCustomerNotRecognizedProperties {
  /**
   * Device ID of the user.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeCustomerRecognizedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * Device ID of the user.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeDashboardCreateAccountProperties {
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
}

export interface FeDashboardLoginProperties {
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
}

export interface FeDashboardLogoutProperties {
  customer_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
}

export interface FeDashboardPageViewProperties {
  customer_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * used in page view events to disnguish the exact page being viewed.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | account, orders, contact_support, faq, otp, login, passkey_add, passkey |
   */
  page: "account" | "orders" | "contact_support" | "faq" | "otp" | "login" | "passkey_add" | "passkey";
}

export interface FeDeleteAddressProperties {
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
}

export interface FeDeletePaymentMethodProperties {
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
}

export interface FeEditAddressProperties {
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  fields_edited: string[];
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
}

export interface FeEditPaymentMethodProperties {
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  fields_edited: string[];
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
}

export interface FeEnrollmentCancelledProperties {
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
}

export interface FeEnrollmentUncheckedProperties {
  /**
   * Device ID of the user.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  direct_payment_request_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * User ID of the user. This should be a unique user identifier of the user and should never change for the same user, e.g. a hashed string of the user's username.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeGuestEnrollmentInitiatedProperties {
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  merchant_id?: any;
  merchant_industry?: any;
  merchant_name?: any;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_id?: string;
  parent_merchant_name?: any;
  /**
   * Status of user at time of event
   *
   *
   * New User
   *
   * Returning User
   *
   * Guest User
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | New User, Returning User, Guest User |
   */
  user_type?: "New User" | "Returning User" | "Guest User";
}

export interface FeIdentityInfoIconClickedProperties {
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
}

export interface FeIdentityVerificationProperties {
  /**
   * For events with a success/fail condition this property indicates which outcome occurred
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Success, Fail, Canceled |
   */
  status: "Success" | "Fail" | "Canceled";
}

export interface FeOtpFailProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeOtpRequestedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeOtpResendCodeProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeOtpSentProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeOtpSubmittedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * Device ID of the user.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeOtpSuccessProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FePasskeyAddedProperties {
  /**
   * For events with a success/fail condition this property indicates which outcome occurred
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Success, Fail, Canceled |
   */
  status: "Success" | "Fail" | "Canceled";
}

export interface FePasskeyDeletedProperties {
  /**
   * For events with a success/fail condition this property indicates which outcome occurred
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Success, Fail, Canceled |
   */
  status: "Success" | "Fail" | "Canceled";
}

export interface FePaymentMethodAddedFailProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_issuer_name?: string;
  card_linked_flag?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_partner_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_network_type?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * Device ID of the user.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  error_code?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
}

export interface FePaymentMethodAddedSuccessProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_issuer_name?: string;
  card_linked_flag?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_partner_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_network_type?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * Device ID of the user.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FePaymentMethodDeletedSuccessProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FePaymentStartedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_issuer_name?: string;
  card_linked_flag?: boolean;
  card_linking_issuer?: any;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_partner_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_netrwork_type?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_network_type?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  tip_amount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FePreOtpPhoneRequestedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  merchant_id?: any;
  merchant_name?: any;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_id?: string;
  parent_merchant_name?: any;
}

export interface FePreOtpPhoneSubmitProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  card_linking_issuer?: string;
  /**
   * Device ID of the user.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FePurchasecompletePageviewProperties {
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  merchant_id?: any;
  merchant_industry?: any;
  merchant_name?: any;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_id?: string;
  parent_merchant_name?: any;
}

export interface FeRequestRightToKnowProperties {
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
}

export interface FeRequestToUnenrollProperties {
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
}

export interface FeReviewpayPageviewProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  flow_type?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface FeSessionExpiredProperties {
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
}

export interface FeSetDefaultAddressProperties {
  address_id: string;
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
}

export interface FeSetDefaultAuthProperties {
  old_type?: string;
  type?: string;
}

export interface FeSetDefaultPaymentMethodProperties {
  card_id: string;
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
}

export interface FeSurveyOpenedProperties {
  flow_type?: any;
  merchant_id?: any;
  merchant_industry?: any;
  merchant_name?: any;
  parent_merchant_name?: any;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * The content of the question displayed to the user on the survey.
   */
  survey_question?: string;
  /**
   * The id of the survey question shown to the user, if the survey question came from an external survey source.
   */
  survey_question_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | yes_no, multiple_choice, free_text |
   */
  survey_question_type?: "yes_no" | "multiple_choice" | "free_text";
  total?: any;
}

export interface FeSurveyResponseProperties {
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  merchant_id?: any;
  merchant_industry?: any;
  merchant_name?: any;
  parent_merchant_name?: any;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * The content of the question displayed to the user on the survey.
   */
  survey_question?: string;
  /**
   * The id of the survey question shown to the user, if the survey question came from an external survey source.
   */
  survey_question_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | yes_no, multiple_choice, free_text |
   */
  survey_question_type?: "yes_no" | "multiple_choice" | "free_text";
  /**
   * The id of the response chosen by the user, if the survey has structured responses that came from an external source.
   */
  survey_response_id?: string;
  /**
   * The content of the response chosen by the user, if the survey has structured respones, or the text of the user response, if free text.
   */
  survey_user_response?: string;
  /**
   * Total of order plus taxes, shipping, fees, etc.
   *
   * Property comes in as an integer. Needs to be converted to a decimal number.  x/100 Needs to be converted to a decimal number. x/100
   */
  total?: any;
}

export interface FeViewAddressesProperties {
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
}

export interface FeViewPaymentMethodsProperties {
  customer_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
}

export interface PaybylinkInitiatedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  customer_id?: string;
  /**
   * Device ID of the user.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * paylink ID. historical GC property
   */
  direct_payment_request_id?: any;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_industry?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  merchant_name?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  parent_merchant_name?: string;
  /**
   * Total amount of order before taxes, shipping, fees, etc. Property
   *
   * comes in as an integer. Needs to be converted to a decimal number. x/100
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  subtotal?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  total?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface UnenrollmentFeSuccessProperties {
  customer_id?: string;
  /**
   * Device ID of the user.
   *
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  device_id?: string;
  /**
   * checkout/user flow event occurred in. EGC, Button, Paylink, etc
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | shakira_paylink_checkout, shakira_button_checkout, shakira_expedited_guest_checkout, shakira_guest_enrollment, paylink_checkout, button_checkout, expedited_guest_checkout, guest_enrollment, shopper_dashboard |
   */
  flow_type?:
    | "shakira_paylink_checkout"
    | "shakira_button_checkout"
    | "shakira_expedited_guest_checkout"
    | "shakira_guest_enrollment"
    | "paylink_checkout"
    | "button_checkout"
    | "expedited_guest_checkout"
    | "guest_enrollment"
    | "shopper_dashboard";
  merchant_id?: any;
  merchant_industry?: any;
  merchant_name?: any;
  /**
   * error code reason. Historical GC
   */
  reason?: any;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  user_type?: string;
}

export interface ExampleMerchantPropertiesProperties {
  merchant_id?: any;
  merchant_industry?: any;
  merchant_name?: any;
  parent_merchant_name?: any;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(public event_properties?: IdentifyProperties) {
    this.event_properties = event_properties;
  }
}

export class FeAddManualPayment implements BaseEvent {
  event_type = "fe_add_manual_payment";

  constructor(public event_properties?: FeAddManualPaymentProperties) {
    this.event_properties = event_properties;
  }
}

export class FeAuth implements BaseEvent {
  event_type = "fe_auth";

  constructor(public event_properties: FeAuthProperties) {
    this.event_properties = event_properties;
  }
}

export class FeAuthChallengeRequested implements BaseEvent {
  event_type = "fe_auth_challenge_requested";

  constructor(public event_properties: FeAuthChallengeRequestedProperties) {
    this.event_properties = event_properties;
  }
}

export class FeCcpaNotificationSeenRightToKnow implements BaseEvent {
  event_type = "fe_ccpa_notification_seen_right_to_know";

  constructor(public event_properties?: FeCcpaNotificationSeenRightToKnowProperties) {
    this.event_properties = event_properties;
  }
}

export class FeCheckoutAsGuest implements BaseEvent {
  event_type = "fe_checkout_as_guest";

  constructor(public event_properties?: FeCheckoutAsGuestProperties) {
    this.event_properties = event_properties;
  }
}

export class FeCheckoutExited implements BaseEvent {
  event_type = "fe_checkout_exited";

  constructor(public event_properties?: FeCheckoutExitedProperties) {
    this.event_properties = event_properties;
  }
}

export class FeCheckoutInitiated implements BaseEvent {
  event_type = "fe_checkout_initiated";

  constructor(public event_properties?: FeCheckoutInitiatedProperties) {
    this.event_properties = event_properties;
  }
}

export class FeClClickedCard implements BaseEvent {
  event_type = "fe_cl_clicked_card";

  constructor(public event_properties?: FeClClickedCardProperties) {
    this.event_properties = event_properties;
  }
}

export class FeClCompleteCardFail implements BaseEvent {
  event_type = "fe_cl_complete_card_fail";

  constructor(public event_properties?: FeClCompleteCardFailProperties) {
    this.event_properties = event_properties;
  }
}

export class FeClCompleteCardRequested implements BaseEvent {
  event_type = "fe_cl_complete_card_requested";

  constructor(public event_properties?: FeClCompleteCardRequestedProperties) {
    this.event_properties = event_properties;
  }
}

export class FeClCompleteCardSuccess implements BaseEvent {
  event_type = "fe_cl_complete_card_success";

  constructor(public event_properties?: FeClCompleteCardSuccessProperties) {
    this.event_properties = event_properties;
  }
}

export class FeClConfirmPanFail implements BaseEvent {
  event_type = "fe_cl_confirm_pan_fail";

  constructor(public event_properties?: FeClConfirmPanFailProperties) {
    this.event_properties = event_properties;
  }
}

export class FeClConfirmPanRequested implements BaseEvent {
  event_type = "fe_cl_confirm_pan_requested";

  constructor(public event_properties?: FeClConfirmPanRequestedProperties) {
    this.event_properties = event_properties;
  }
}

export class FeClConfirmPanSuccess implements BaseEvent {
  event_type = "fe_cl_confirm_pan_success";

  constructor(public event_properties?: FeClConfirmPanSuccessProperties) {
    this.event_properties = event_properties;
  }
}

export class FeClIssuerChallengeExited implements BaseEvent {
  event_type = "fe_cl_issuer_challenge_exited";

  constructor(public event_properties?: FeClIssuerChallengeExitedProperties) {
    this.event_properties = event_properties;
  }
}

export class FeClIssuerChallengeFail implements BaseEvent {
  event_type = "fe_cl_issuer_challenge_fail";

  constructor(public event_properties?: FeClIssuerChallengeFailProperties) {
    this.event_properties = event_properties;
  }
}

export class FeClIssuerChallengeReviewpayPage implements BaseEvent {
  event_type = "fe_cl_issuer_challenge_reviewpay_page";

  constructor(public event_properties?: FeClIssuerChallengeReviewpayPageProperties) {
    this.event_properties = event_properties;
  }
}

export class FeClIssuerChallengeSelectpaymentPage implements BaseEvent {
  event_type = "fe_cl_issuer_challenge_selectpayment_page";

  constructor(public event_properties?: FeClIssuerChallengeSelectpaymentPageProperties) {
    this.event_properties = event_properties;
  }
}

export class FeClIssuerChallengeSuccess implements BaseEvent {
  event_type = "fe_cl_issuer_challenge_success";

  constructor(public event_properties?: FeClIssuerChallengeSuccessProperties) {
    this.event_properties = event_properties;
  }
}

export class FeClMetadataRequestFail implements BaseEvent {
  event_type = "fe_cl_metadata_request_fail";

  constructor(public event_properties?: FeClMetadataRequestFailProperties) {
    this.event_properties = event_properties;
  }
}

export class FeClMetadataRequestSuccess implements BaseEvent {
  event_type = "fe_cl_metadata_request_success";

  constructor(public event_properties?: FeClMetadataRequestSuccessProperties) {
    this.event_properties = event_properties;
  }
}

export class FeClMetadataRequested implements BaseEvent {
  event_type = "fe_cl_metadata_requested";

  constructor(public event_properties?: FeClMetadataRequestedProperties) {
    this.event_properties = event_properties;
  }
}

export class FeClReviewpaywalletPage implements BaseEvent {
  event_type = "fe_cl_reviewpaywallet_page";

  constructor(public event_properties?: FeClReviewpaywalletPageProperties) {
    this.event_properties = event_properties;
  }
}

export class FeClSelectpaymentPage implements BaseEvent {
  event_type = "fe_cl_selectpayment_page";

  constructor(public event_properties: FeClSelectpaymentPageProperties) {
    this.event_properties = event_properties;
  }
}

export class FeConsentToUnenroll implements BaseEvent {
  event_type = "fe_consent_to_unenroll";

  constructor(public event_properties?: FeConsentToUnenrollProperties) {
    this.event_properties = event_properties;
  }
}

export class FeContactSupportSubmitted implements BaseEvent {
  event_type = "fe_contact_support_submitted";

  constructor(public event_properties: FeContactSupportSubmittedProperties) {
    this.event_properties = event_properties;
  }
}

export class FeCreateAddress implements BaseEvent {
  event_type = "fe_create_address";

  constructor(public event_properties?: FeCreateAddressProperties) {
    this.event_properties = event_properties;
  }
}

export class FeCreatePaymentMethod implements BaseEvent {
  event_type = "fe_create_payment_method";

  constructor(public event_properties?: FeCreatePaymentMethodProperties) {
    this.event_properties = event_properties;
  }
}

export class FeCustomerAccountCreated implements BaseEvent {
  event_type = "fe_customer_account_created";

  constructor(public event_properties?: FeCustomerAccountCreatedProperties) {
    this.event_properties = event_properties;
  }
}

export class FeCustomerDeviceNotRecognized implements BaseEvent {
  event_type = "fe_customer_device_not_recognized";

  constructor(public event_properties?: FeCustomerDeviceNotRecognizedProperties) {
    this.event_properties = event_properties;
  }
}

export class FeCustomerDeviceRecognized implements BaseEvent {
  event_type = "fe_customer_device_recognized";

  constructor(public event_properties?: FeCustomerDeviceRecognizedProperties) {
    this.event_properties = event_properties;
  }
}

export class FeCustomerNotRecognized implements BaseEvent {
  event_type = "fe_customer_not_recognized";

  constructor(public event_properties?: FeCustomerNotRecognizedProperties) {
    this.event_properties = event_properties;
  }
}

export class FeCustomerRecognized implements BaseEvent {
  event_type = "fe_customer_recognized";

  constructor(public event_properties?: FeCustomerRecognizedProperties) {
    this.event_properties = event_properties;
  }
}

export class FeDashboardCreateAccount implements BaseEvent {
  event_type = "fe_dashboard_create_account";

  constructor(public event_properties?: FeDashboardCreateAccountProperties) {
    this.event_properties = event_properties;
  }
}

export class FeDashboardLogin implements BaseEvent {
  event_type = "fe_dashboard_login";

  constructor(public event_properties?: FeDashboardLoginProperties) {
    this.event_properties = event_properties;
  }
}

export class FeDashboardLogout implements BaseEvent {
  event_type = "fe_dashboard_logout";

  constructor(public event_properties?: FeDashboardLogoutProperties) {
    this.event_properties = event_properties;
  }
}

export class FeDashboardPageView implements BaseEvent {
  event_type = "fe_dashboard_page_view";

  constructor(public event_properties: FeDashboardPageViewProperties) {
    this.event_properties = event_properties;
  }
}

export class FeDeleteAddress implements BaseEvent {
  event_type = "fe_delete_address";

  constructor(public event_properties?: FeDeleteAddressProperties) {
    this.event_properties = event_properties;
  }
}

export class FeDeletePaymentMethod implements BaseEvent {
  event_type = "fe_delete_payment_method";

  constructor(public event_properties?: FeDeletePaymentMethodProperties) {
    this.event_properties = event_properties;
  }
}

export class FeEditAddress implements BaseEvent {
  event_type = "fe_edit_address";

  constructor(public event_properties: FeEditAddressProperties) {
    this.event_properties = event_properties;
  }
}

export class FeEditPaymentMethod implements BaseEvent {
  event_type = "fe_edit_payment_method";

  constructor(public event_properties: FeEditPaymentMethodProperties) {
    this.event_properties = event_properties;
  }
}

export class FeEnrollmentCancelled implements BaseEvent {
  event_type = "fe_enrollment_cancelled";

  constructor(public event_properties?: FeEnrollmentCancelledProperties) {
    this.event_properties = event_properties;
  }
}

export class FeEnrollmentUnchecked implements BaseEvent {
  event_type = "fe_enrollment_unchecked";

  constructor(public event_properties?: FeEnrollmentUncheckedProperties) {
    this.event_properties = event_properties;
  }
}

export class FeGuestEnrollmentInitiated implements BaseEvent {
  event_type = "fe_guest_enrollment_initiated";

  constructor(public event_properties?: FeGuestEnrollmentInitiatedProperties) {
    this.event_properties = event_properties;
  }
}

export class FeIdentityInfoIconClicked implements BaseEvent {
  event_type = "fe_identity_info_icon_clicked";

  constructor(public event_properties?: FeIdentityInfoIconClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class FeIdentityVerification implements BaseEvent {
  event_type = "fe_identity_verification";

  constructor(public event_properties: FeIdentityVerificationProperties) {
    this.event_properties = event_properties;
  }
}

export class FeOtpChangePhone implements BaseEvent {
  event_type = "fe_otp_change_phone";
}

export class FeOtpFail implements BaseEvent {
  event_type = "fe_otp_fail";

  constructor(public event_properties?: FeOtpFailProperties) {
    this.event_properties = event_properties;
  }
}

export class FeOtpRequested implements BaseEvent {
  event_type = "fe_otp_requested";

  constructor(public event_properties?: FeOtpRequestedProperties) {
    this.event_properties = event_properties;
  }
}

export class FeOtpResendCode implements BaseEvent {
  event_type = "fe_otp_resend_code";

  constructor(public event_properties?: FeOtpResendCodeProperties) {
    this.event_properties = event_properties;
  }
}

export class FeOtpSent implements BaseEvent {
  event_type = "fe_otp_sent";

  constructor(public event_properties?: FeOtpSentProperties) {
    this.event_properties = event_properties;
  }
}

export class FeOtpSubmitted implements BaseEvent {
  event_type = "fe_otp_submitted";

  constructor(public event_properties?: FeOtpSubmittedProperties) {
    this.event_properties = event_properties;
  }
}

export class FeOtpSuccess implements BaseEvent {
  event_type = "fe_otp_success";

  constructor(public event_properties?: FeOtpSuccessProperties) {
    this.event_properties = event_properties;
  }
}

export class FeOtpVerificationSent implements BaseEvent {
  event_type = "fe_otp_verification_sent";
}

export class FePasskeyAddRequest implements BaseEvent {
  event_type = "fe_passkey_add_request";
}

export class FePasskeyAdded implements BaseEvent {
  event_type = "fe_passkey_added";

  constructor(public event_properties: FePasskeyAddedProperties) {
    this.event_properties = event_properties;
  }
}

export class FePasskeyAuthRetry implements BaseEvent {
  event_type = "fe_passkey_auth_retry";
}

export class FePasskeyDeleted implements BaseEvent {
  event_type = "fe_passkey_deleted";

  constructor(public event_properties: FePasskeyDeletedProperties) {
    this.event_properties = event_properties;
  }
}

export class FePaymentMethodAddedFail implements BaseEvent {
  event_type = "fe_payment_method_added_fail";

  constructor(public event_properties?: FePaymentMethodAddedFailProperties) {
    this.event_properties = event_properties;
  }
}

export class FePaymentMethodAddedSuccess implements BaseEvent {
  event_type = "fe_payment_method_added_success";

  constructor(public event_properties?: FePaymentMethodAddedSuccessProperties) {
    this.event_properties = event_properties;
  }
}

export class FePaymentMethodDeletedSuccess implements BaseEvent {
  event_type = "fe_payment_method_deleted_success";

  constructor(public event_properties?: FePaymentMethodDeletedSuccessProperties) {
    this.event_properties = event_properties;
  }
}

export class FePaymentStarted implements BaseEvent {
  event_type = "fe_payment_started";

  constructor(public event_properties?: FePaymentStartedProperties) {
    this.event_properties = event_properties;
  }
}

export class FePreOtpPhoneRequested implements BaseEvent {
  event_type = "fe_pre_otp_phone_requested";

  constructor(public event_properties?: FePreOtpPhoneRequestedProperties) {
    this.event_properties = event_properties;
  }
}

export class FePreOtpPhoneSubmit implements BaseEvent {
  event_type = "fe_pre_otp_phone_submit";

  constructor(public event_properties?: FePreOtpPhoneSubmitProperties) {
    this.event_properties = event_properties;
  }
}

export class FePurchasecompletePageview implements BaseEvent {
  event_type = "fe_purchasecomplete_pageview";

  constructor(public event_properties?: FePurchasecompletePageviewProperties) {
    this.event_properties = event_properties;
  }
}

export class FeRequestRightToKnow implements BaseEvent {
  event_type = "fe_request_right_to_know";

  constructor(public event_properties?: FeRequestRightToKnowProperties) {
    this.event_properties = event_properties;
  }
}

export class FeRequestToUnenroll implements BaseEvent {
  event_type = "fe_request_to_unenroll";

  constructor(public event_properties?: FeRequestToUnenrollProperties) {
    this.event_properties = event_properties;
  }
}

export class FeReviewpayPageview implements BaseEvent {
  event_type = "fe_reviewpay_pageview";

  constructor(public event_properties?: FeReviewpayPageviewProperties) {
    this.event_properties = event_properties;
  }
}

export class FeSessionExpired implements BaseEvent {
  event_type = "fe_session_expired";

  constructor(public event_properties?: FeSessionExpiredProperties) {
    this.event_properties = event_properties;
  }
}

export class FeSetDefaultAddress implements BaseEvent {
  event_type = "fe_set_default_address";

  constructor(public event_properties: FeSetDefaultAddressProperties) {
    this.event_properties = event_properties;
  }
}

export class FeSetDefaultAuth implements BaseEvent {
  event_type = "fe_set_default_auth";

  constructor(public event_properties?: FeSetDefaultAuthProperties) {
    this.event_properties = event_properties;
  }
}

export class FeSetDefaultPaymentMethod implements BaseEvent {
  event_type = "fe_set_default_payment_method";

  constructor(public event_properties: FeSetDefaultPaymentMethodProperties) {
    this.event_properties = event_properties;
  }
}

export class FeSurveyOpened implements BaseEvent {
  event_type = "fe_survey_opened";

  constructor(public event_properties?: FeSurveyOpenedProperties) {
    this.event_properties = event_properties;
  }
}

export class FeSurveyResponse implements BaseEvent {
  event_type = "fe_survey_response";

  constructor(public event_properties?: FeSurveyResponseProperties) {
    this.event_properties = event_properties;
  }
}

export class FeViewAddresses implements BaseEvent {
  event_type = "fe_view_addresses";

  constructor(public event_properties?: FeViewAddressesProperties) {
    this.event_properties = event_properties;
  }
}

export class FeViewPaymentMethods implements BaseEvent {
  event_type = "fe_view_payment_methods";

  constructor(public event_properties?: FeViewPaymentMethodsProperties) {
    this.event_properties = event_properties;
  }
}

export class PaybylinkInitiated implements BaseEvent {
  event_type = "paybylink_initiated";

  constructor(public event_properties?: PaybylinkInitiatedProperties) {
    this.event_properties = event_properties;
  }
}

export class UnenrollmentFeSuccess implements BaseEvent {
  event_type = "unenrollment_fe_success";

  constructor(public event_properties?: UnenrollmentFeSuccessProperties) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * fe_add_manual_payment
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_add_manual_payment)
   *
   * **Triggers:** when a user selects "use different card" from the Select Payment CL screen OR when the user selects “add card” from the Wallet screen.
   *
   * **Description:** Tracking how often users are selecting to add a card manually, when cl is present or not within checkout select payment screen or wallet screens
   *
   * Owner: rachel@skipify.com
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  feAddManualPayment(
    properties?: FeAddManualPaymentProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeAddManualPayment(properties), options);
  }

  /**
   * fe_auth
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_auth)
   *
   * This event tracks the authentication process for users on our platform. It captures data related to successful and failed login attempts and the method used like OTP or passkeys
   *
   * @param properties The event's properties (e.g. auth_method)
   * @param options Amplitude event options.
   */
  feAuth(
    properties: FeAuthProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeAuth(properties), options);
  }

  /**
   * fe_auth_challenge_requested
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_auth_challenge_requested)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. auth_method)
   * @param options Amplitude event options.
   */
  feAuthChallengeRequested(
    properties: FeAuthChallengeRequestedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeAuthChallengeRequested(properties), options);
  }

  /**
   * fe_ccpa_notification_seen_right_to_know
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_ccpa_notification_seen_right_to_know)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. flow_type)
   * @param options Amplitude event options.
   */
  feCcpaNotificationSeenRightToKnow(
    properties?: FeCcpaNotificationSeenRightToKnowProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeCcpaNotificationSeenRightToKnow(properties), options);
  }

  /**
   * fe_checkout_as_guest
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_checkout_as_guest)
   *
   * **Triggers:** when a user selects the "checkout as guest" button on the OTP modal/page.
   *
   *
   * **Description:** The user selects "continue as guest" without entering a OTP code.
   *
   * Owner: Jesus
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  feCheckoutAsGuest(
    properties?: FeCheckoutAsGuestProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeCheckoutAsGuest(properties), options);
  }

  /**
   * fe_checkout_exited
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_checkout_exited)
   *
   * **Triggers:** when a user selects "x" or "exit checkout" to exit out of the checkout modal. 
   *
   *
   * **Description:** The OTP modal/page was displayed but the user exits without entering an OTP code. 
   *  This event is merged with the following historical events: 
   *  1. guestredirect_sdk_otp_cancelled = flow_type:expedited_guest_checkout
   *
   * Owner: Jesus
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  feCheckoutExited(
    properties?: FeCheckoutExitedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeCheckoutExited(properties), options);
  }

  /**
   * fe_checkout_initiated
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_checkout_initiated)
   *
   * This event marks the start of the checkout flow in the front end for egc and button flows
   *
   * @param properties The event's properties (e.g. flow_type)
   * @param options Amplitude event options.
   */
  feCheckoutInitiated(
    properties?: FeCheckoutInitiatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeCheckoutInitiated(properties), options);
  }

  /**
   * fe_cl_clicked_card
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_cl_clicked_card)
   *
   * **Triggers:** when a user clicks on a stored CL or a new CL card option.
   *
   *
   * **Description:** We want to understand how a user interacts with CL card options in the Select Payment or Wallet page, before they confirm what type of payment method they want to use. This event is for every time a user clicks on a new CL card option or a stored CL option before they confirm they want to use that CL card for the transaction.
   *
   * Owner: rachel@skipify.com
   *
   * @param properties The event's properties (e.g. card_issuer_name)
   * @param options Amplitude event options.
   */
  feClClickedCard(
    properties?: FeClClickedCardProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeClClickedCard(properties), options);
  }

  /**
   * fe_cl_complete_card_fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_cl_complete_card_fail)
   *
   * **Triggers:** when the complete_pan response from the card linking issuer fails on the front end.
   *
   * **Description:** The request for the full CL card information failed after the user successfully completed the step-up and the user is unable to use the CL card for the transaction. CL card is not added to the wallet and the user has to use an existing stored payment method or add a payment method manually. The user is redirected to the Add Payment Method form OR their Wallet to select another payment method after this event.
   *
   * Owner: Jesus
   *
   * @param properties The event's properties (e.g. card_brand)
   * @param options Amplitude event options.
   */
  feClCompleteCardFail(
    properties?: FeClCompleteCardFailProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeClCompleteCardFail(properties), options);
  }

  /**
   * fe_cl_complete_card_requested
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_cl_complete_card_requested)
   *
   * **Triggers:** when the complete_pan request is sent from the front end to the issuer to confirm whether step-up was successful.
   *
   * **Description:** The user successfully completed the issuer challenge and the full CL card data is requested.
   *
   * Owner: Jesus
   *
   * @param properties The event's properties (e.g. card_issuer_name)
   * @param options Amplitude event options.
   */
  feClCompleteCardRequested(
    properties?: FeClCompleteCardRequestedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeClCompleteCardRequested(properties), options);
  }

  /**
   * fe_cl_complete_card_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_cl_complete_card_success)
   *
   * **Triggers:** when the complete_pan response from the card linking issuer is successful on the front end.
   *
   * **Description:** If the user successfully completes the issuer challenge and the full card details were received from the issuer, the user is able to use the card for the current transaction. If this is the first time the user is completing CL with this card, the card is officially added to the users's wallet after this event.
   *
   * Owner: Jesus
   *
   * @param properties The event's properties (e.g. card_issuer_name)
   * @param options Amplitude event options.
   */
  feClCompleteCardSuccess(
    properties?: FeClCompleteCardSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeClCompleteCardSuccess(properties), options);
  }

  /**
   * fe_cl_confirm_pan_fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_cl_confirm_pan_fail)
   *
   * **Triggers:** when confirm_pan response from the card linking issuer fails on the front end.
   *
   * **Description:** The front end was unable to retrieve the CL pan and the CL card is not available to use for the payment method. The user is brought to an error screen and either must select to use an existing stored payment method, OR add a new card manually. CL session is ended after fail and CL is not available.
   *
   * Owner: rachel@skipify.com
   *
   * @param properties The event's properties (e.g. card_linking_issuer)
   * @param options Amplitude event options.
   */
  feClConfirmPanFail(
    properties?: FeClConfirmPanFailProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeClConfirmPanFail(properties), options);
  }

  /**
   * fe_cl_confirm_pan_requested
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_cl_confirm_pan_requested)
   *
   * **Triggers:** when the confirm_pan request is sent.
   *
   *
   * **Description:** We want to understand when a user has finalized that they want to use a CL card for their payment method. The confirm_pan call is sent when the user selects a CL card and clicks "continue"/"confirm" from the Select Payment page OR clicks “Pay” on the Review and Pay page after selecting a CL card from the wallet.
   *
   * Owner: rachel@skipify.com
   *
   * @param properties The event's properties (e.g. card_issuer_name)
   * @param options Amplitude event options.
   */
  feClConfirmPanRequested(
    properties?: FeClConfirmPanRequestedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeClConfirmPanRequested(properties), options);
  }

  /**
   * fe_cl_confirm_pan_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_cl_confirm_pan_success)
   *
   * **Triggers:** when the confirm_pan response from the card linking issuer is successful on the frontend.
   *
   *
   * **Description:** The front end was able to retrieve the CL pan and the user can continue with that payment method in the CL flow. If no step-up is required, the CL card can be used in the transaction and is officially added to the user's wallet after this event. If a step-up is required, the card is not able to be used for the transaction until the complete_pan request is successful.
   *
   * Owner: rachel@skipify.com
   *
   * @param properties The event's properties (e.g. card_issuer_name)
   * @param options Amplitude event options.
   */
  feClConfirmPanSuccess(
    properties?: FeClConfirmPanSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeClConfirmPanSuccess(properties), options);
  }

  /**
   * fe_cl_issuer_challenge_exited
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_cl_issuer_challenge_exited)
   *
   * **Triggers:** when a user exits out of the the issuer challenge by clicking on the 'x'.
   *
   * **Description:** user skipped challenge
   *
   * Owner: rachel@skipify.com
   *
   * @param properties The event's properties (e.g. card_linking_issuer)
   * @param options Amplitude event options.
   */
  feClIssuerChallengeExited(
    properties?: FeClIssuerChallengeExitedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeClIssuerChallengeExited(properties), options);
  }

  /**
   * fe_cl_issuer_challenge_fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_cl_issuer_challenge_fail)
   *
   * **Triggers:** when the response form the issuer challenge fails on the frontend. 
   *
   * **Description:** The response back from the issuer challenge failed, the user is unable to use the CL card and they are redirected to the Add Payment Method form OR their Wallet to select another payment method.
   *
   * Owner: Jesus
   *
   * @param properties The event's properties (e.g. card_linking_issuer)
   * @param options Amplitude event options.
   */
  feClIssuerChallengeFail(
    properties?: FeClIssuerChallengeFailProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeClIssuerChallengeFail(properties), options);
  }

  /**
   * fe_cl_issuer_challenge_reviewpay_page
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_cl_issuer_challenge_reviewpay_page)
   *
   * **Triggers:** when the front end receives an issuer challenge/step-up URL within the confirm_pan response from the issuer. Review & Pay page
   *
   * **Description:** The user has a CL card selected from their wallet, clicks “Pay” on the Review and Pay page, and the issuer requires a challenge/step-up (additional authentication step) before the user is able to officially link the card and use it in a transaction. The CL challenge is triggered after the confirm_pan request is sent and the response from the issuer includes a URL for the step-up challenge. The user is redirected to the issuer challenge page. For amex this step up is CVC.
   *
   * Owner: Jesus
   *
   * @param properties The event's properties (e.g. card_linking_issuer)
   * @param options Amplitude event options.
   */
  feClIssuerChallengeReviewpayPage(
    properties?: FeClIssuerChallengeReviewpayPageProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeClIssuerChallengeReviewpayPage(properties), options);
  }

  /**
   * fe_cl_issuer_challenge_selectpayment_page
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_cl_issuer_challenge_selectpayment_page)
   *
   * **Triggers:** when the front end receives an issuer challenge/step-up URL within the confirm_pan response from the issuer. Select Payment Page.
   *
   * **Description:** The user selects a CL card from the Select Payment page and the issuer requires a challenge/step-up (additional authentication step) before the user is able to officially link the card and use it in a transaction. The CL challenge is triggered after the confirm_pan request is sent and the response from the issuer includes a URL for the step-up challenge. The user is redirected to the issuer challenge page. For amex this step up is CVC.
   *
   * Owner: Jesus
   *
   * @param properties The event's properties (e.g. card_issuer_name)
   * @param options Amplitude event options.
   */
  feClIssuerChallengeSelectpaymentPage(
    properties?: FeClIssuerChallengeSelectpaymentPageProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeClIssuerChallengeSelectpaymentPage(properties), options);
  }

  /**
   * fe_cl_issuer_challenge_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_cl_issuer_challenge_success)
   *
   * **Triggers:** when the response from the issuer challenge is successful on the frontend.
   *
   * **Description:** The response back from the issuer challenge was successful, the user is brought to the next page in the flow where they are able to use their CL card
   *
   * Owner: Jesus
   *
   * @param properties The event's properties (e.g. card_linking_issuer)
   * @param options Amplitude event options.
   */
  feClIssuerChallengeSuccess(
    properties?: FeClIssuerChallengeSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeClIssuerChallengeSuccess(properties), options);
  }

  /**
   * fe_cl_metadata_request_fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_cl_metadata_request_fail)
   *
   * **Triggers:** when the retrieval of the CL metadata is unsuccessful on the front end.
   *
   *
   * **Description:** The front end requested to receive the CL metadata from the CL service/issuer, but the call failed and the metadata isn't able to be displayed on the front end. The user is unable to see/access card linking options.
   *
   * Owner: rachel@skipify.com
   *
   * @param properties The event's properties (e.g. card_linking_issuer)
   * @param options Amplitude event options.
   */
  feClMetadataRequestFail(
    properties?: FeClMetadataRequestFailProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeClMetadataRequestFail(properties), options);
  }

  /**
   * fe_cl_metadata_request_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_cl_metadata_request_success)
   *
   * **Triggers:** when the CL metadata is received to be displayed to the user on the front end within the Select Payment or Wallet page.
   *
   *
   * **Description:** The front end requested to receive the CL metadata from the CL service/issuer, the call was successful and the CL metadata is displayed on the select payment or wallet page. Retrieve_pan call success.
   *
   * Owner: rachel@skipify.com
   *
   * @param properties The event's properties (e.g. card_issuer_name)
   * @param options Amplitude event options.
   */
  feClMetadataRequestSuccess(
    properties?: FeClMetadataRequestSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeClMetadataRequestSuccess(properties), options);
  }

  /**
   * fe_cl_metadata_requested
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_cl_metadata_requested)
   *
   * **Triggers:** when the front end requests the card linking metadata from the card linking service/issuer.
   *
   *
   * **Description:** The user has successfully OTP'd and the frontend requests CL card metadata from the card-linking service/issuer to display on the Select Payment or wallet pages. When /retreive_pan call is made
   *
   * Owner: rachel@skipify.com
   *
   * @param properties The event's properties (e.g. card_linking_issuer)
   * @param options Amplitude event options.
   */
  feClMetadataRequested(
    properties?: FeClMetadataRequestedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeClMetadataRequested(properties), options);
  }

  /**
   * fe_cl_reviewpaywallet_page
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_cl_reviewpaywallet_page)
   *
   * **Triggers:** when a user views their wallet (from the Review and Pay page) where stored or new CL card options are presented.
   *
   *
   * **Description:** When card linking options are presented in the wallet after the user selects to change payment method from the Review and Pay page.
   *
   * Owner: rachel@skipify.com
   *
   * @param properties The event's properties (e.g. amex_cl_options_count)
   * @param options Amplitude event options.
   */
  feClReviewpaywalletPage(
    properties?: FeClReviewpaywalletPageProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeClReviewpaywalletPage(properties), options);
  }

  /**
   * fe_cl_selectpayment_page
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_cl_selectpayment_page)
   *
   * **Triggers:** when a user lands on the Select Payment page where card linking options are presented.** 
   *
   *  Description:** After successfully OTPing, card linking options are presented to a new user or a returning user without existing stored payment methods, within the Select Payment page.
   *
   * Owner: rachel@skipify.com
   *
   * @param properties The event's properties (e.g. amex_cl_options_count)
   * @param options Amplitude event options.
   */
  feClSelectpaymentPage(
    properties: FeClSelectpaymentPageProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeClSelectpaymentPage(properties), options);
  }

  /**
   * fe_consent_to_unenroll
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_consent_to_unenroll)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. flow_type)
   * @param options Amplitude event options.
   */
  feConsentToUnenroll(
    properties?: FeConsentToUnenrollProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeConsentToUnenroll(properties), options);
  }

  /**
   * fe_contact_support_submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_contact_support_submitted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  feContactSupportSubmitted(
    properties: FeContactSupportSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeContactSupportSubmitted(properties), options);
  }

  /**
   * fe_create_address
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_create_address)
   *
   * This event is triggered when a user creates a new address in the checkout process
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  feCreateAddress(
    properties?: FeCreateAddressProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeCreateAddress(properties), options);
  }

  /**
   * fe_create_payment_method
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_create_payment_method)
   *
   * This event tracks when a user creates a new payment method during the checkout process
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  feCreatePaymentMethod(
    properties?: FeCreatePaymentMethodProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeCreatePaymentMethod(properties), options);
  }

  /**
   * fe_customer_account_created
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_customer_account_created)
   *
   * **Triggers:** when the customer validates their first one-time passcode -> this flags the customer as "validated" in our database. 
   *
   * **Description:** A first-time, unrecognized, user entered OTP and OTP was successful and the customer becomes a verified user. Next time the customer returns with the same email, they will be a recognized user. This event is only in Shakira and will be replaced with a backend event to track customer account creation.
   *
   * Owner: Jesus
   *
   * @param properties The event's properties (e.g. device_id)
   * @param options Amplitude event options.
   */
  feCustomerAccountCreated(
    properties?: FeCustomerAccountCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeCustomerAccountCreated(properties), options);
  }

  /**
   * fe_customer_device_not_recognized
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_customer_device_not_recognized)
   *
   * **Trigger**: When the customer was not recognized by their device
   *
   * @param properties The event's properties (e.g. device_id)
   * @param options Amplitude event options.
   */
  feCustomerDeviceNotRecognized(
    properties?: FeCustomerDeviceNotRecognizedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeCustomerDeviceNotRecognized(properties), options);
  }

  /**
   * fe_customer_device_recognized
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_customer_device_recognized)
   *
   * **Trigger**: When the customer was recognized by their device
   *
   * @param properties The event's properties (e.g. device_id)
   * @param options Amplitude event options.
   */
  feCustomerDeviceRecognized(
    properties?: FeCustomerDeviceRecognizedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeCustomerDeviceRecognized(properties), options);
  }

  /**
   * fe_customer_not_recognized
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_customer_not_recognized)
   *
   * **Triggers:** when the customer lookup returns that the user email is not recognized as a verified Skipify/GoCart user. 
   *
   *
   * **Description:** The user's email was not recognized and they do not have an existing account with Skipfy or GC. The customer is not recognized. For the PBL flow, this event was added after march 2023. Do not use. Planning on depreciating. The customer has never completed OTP. This event is merged with the following historical events: 
   *
   * 1\. express_fe_customer_not_recognized = flow_type:button_checkout 
   *
   * 2\. guestredirect_fe_customer_not_recognized = flow_type:expedited_guest_checkout
   *
   * Owner: rachel@skipify.com
   *
   * @param properties The event's properties (e.g. device_id)
   * @param options Amplitude event options.
   */
  feCustomerNotRecognized(
    properties?: FeCustomerNotRecognizedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeCustomerNotRecognized(properties), options);
  }

  /**
   * fe_customer_recognized
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_customer_recognized)
   *
   * **Triggers:** when the customer lookup call returns that the user's email is recognized as a verified Skipify/GoCart user. 
   *
   * **Description:** The user's email was recognized and they have an existing account with Skipfy and/or GC. The customer is recognized as returning. The customer has previously completed enrollment OTP and is a verified user. For the PBL flow, this event was added after march 2023. Do not use. Planning on depreciating. This event is merged with the following historical events: 
   *
   * 1\. express_fe_customer_recognized = flow_type:button_checkout 2. guestredirect_fe_customer_recognized = flow_type:expedited_guest_checkout
   *
   * Owner: rachel@skipify.com
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  feCustomerRecognized(
    properties?: FeCustomerRecognizedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeCustomerRecognized(properties), options);
  }

  /**
   * fe_dashboard_create_account
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_dashboard_create_account)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. flow_type)
   * @param options Amplitude event options.
   */
  feDashboardCreateAccount(
    properties?: FeDashboardCreateAccountProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeDashboardCreateAccount(properties), options);
  }

  /**
   * fe_dashboard_login
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_dashboard_login)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. flow_type)
   * @param options Amplitude event options.
   */
  feDashboardLogin(
    properties?: FeDashboardLoginProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeDashboardLogin(properties), options);
  }

  /**
   * fe_dashboard_logout
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_dashboard_logout)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  feDashboardLogout(
    properties?: FeDashboardLogoutProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeDashboardLogout(properties), options);
  }

  /**
   * fe_dashboard_page_view
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_dashboard_page_view)
   *
   * An event that fires when the user lands on different pages of the Shopper Dashboard. The pages are distinguished through the 'page' property.
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  feDashboardPageView(
    properties: FeDashboardPageViewProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeDashboardPageView(properties), options);
  }

  /**
   * fe_delete_address
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_delete_address)
   *
   * This event tracks when a user deletes an address from their account
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  feDeleteAddress(
    properties?: FeDeleteAddressProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeDeleteAddress(properties), options);
  }

  /**
   * fe_delete_payment_method
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_delete_payment_method)
   *
   * This event tracks when a user deletes a payment method from their account.
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  feDeletePaymentMethod(
    properties?: FeDeletePaymentMethodProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeDeletePaymentMethod(properties), options);
  }

  /**
   * fe_edit_address
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_edit_address)
   *
   * This event is triggered when a user edits their address information during the checkout process
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  feEditAddress(
    properties: FeEditAddressProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeEditAddress(properties), options);
  }

  /**
   * fe_edit_payment_method
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_edit_payment_method)
   *
   * This event tracks when a user edits their payment method during the checkout process
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  feEditPaymentMethod(
    properties: FeEditPaymentMethodProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeEditPaymentMethod(properties), options);
  }

  /**
   * fe_enrollment_cancelled
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_enrollment_cancelled)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. flow_type)
   * @param options Amplitude event options.
   */
  feEnrollmentCancelled(
    properties?: FeEnrollmentCancelledProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeEnrollmentCancelled(properties), options);
  }

  /**
   * fe_enrollment_unchecked
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_enrollment_unchecked)
   *
   * **Triggers:** when the user unchecks the enrollment checkbox in the paylink or guest enrollment flows.
   *
   * **Description:** The customer clicked to uncheck the enrollment checkbox and will not trigger the enrollment flow in Skipify. The checkbox is only present on Paylink and Guest Enrollment flows. In GC, on Guest Enrollment in this event is on the SDK but for Paylink checkout, this event is tagged in GC code. This event replaced historical events:
   *
   * \- guestenrollment_fe_enrollment_unchecked
   *
   * \- paybylink_fe_enrollment_unchecked
   *
   * Owner: rachel@skipify.com
   *
   * @param properties The event's properties (e.g. device_id)
   * @param options Amplitude event options.
   */
  feEnrollmentUnchecked(
    properties?: FeEnrollmentUncheckedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeEnrollmentUnchecked(properties), options);
  }

  /**
   * fe_guest_enrollment_initiated
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_guest_enrollment_initiated)
   *
   * This event tracks when a guest initiates the enrollment process, either through the checkbox on the merchant site or the midflow user promotion
   *
   * @param properties The event's properties (e.g. flow_type)
   * @param options Amplitude event options.
   */
  feGuestEnrollmentInitiated(
    properties?: FeGuestEnrollmentInitiatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeGuestEnrollmentInitiated(properties), options);
  }

  /**
   * fe_identity_info_icon_clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_identity_info_icon_clicked)
   *
   * **Triggers:** When the user clicks the info icon on the Identity page.
   *
   *
   * **Description:** We want to understand how often a user interacts with the info icon on the Identity (contact info) screen after the Skipify experience loads for a new user via email lookup. This should be added to the Card Linking space so it can account for new users being looked up via email and the Skipify experience initiating after a card issuer recognizes them.
   *
   * @param properties The event's properties (e.g. flow_type)
   * @param options Amplitude event options.
   */
  feIdentityInfoIconClicked(
    properties?: FeIdentityInfoIconClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeIdentityInfoIconClicked(properties), options);
  }

  /**
   * fe_identity_verification
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_identity_verification)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. status)
   * @param options Amplitude event options.
   */
  feIdentityVerification(
    properties: FeIdentityVerificationProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeIdentityVerification(properties), options);
  }

  /**
   * fe_otp_change_phone
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_otp_change_phone)
   *
   * **Triggers:** when a new user selects "change phone number" on the OTP modal/page.
   *
   * **Description:** A new user selects "change phone number" on the OTP page/screen. The user is brought back to the beginning of the sign-up flow to re-enter the phone number.
   *
   * Owner: Jesus
   *
   * @param options Amplitude event options.
   */
  feOtpChangePhone(
    options?: EventOptions,
  ) {
    return this.track(new FeOtpChangePhone(), options);
  }

  /**
   * fe_otp_fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_otp_fail)
   *
   * **Triggers:** when a user enters an incorrect OTP code.
   *
   *
   * **Description:** The user entered an invalid OTP code. Error is displayed on the screen. Customers can fail with the same OTP multiple times. OTP Fails+ OTP Successes+ # of OTPs submitted. This event is merged with the following historical events:
   *
   * 1\. guestenrollment_fe_otp_failed = flow_type:guest_enrollment
   *
   * 2\. api_otp_denied
   *
   * Owner: rachel@skipify.com
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  feOtpFail(
    properties?: FeOtpFailProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeOtpFail(properties), options);
  }

  /**
   * fe_otp_requested
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_otp_requested)
   *
   * **Triggers:** when the OTP screen/modal is displayed in the UI. 
   *
   *
   *
   * **Description:** OTP screen is displayed and an OTP entry is required to proceed. For a new user, at this step, the user has been (temporarily\*) added to DB, but is not a verified user until they complete OTP. If a user doesn't complete OTP within 30 days, the their data is cleared.
   *
   * Owner: rachel@skipify.com
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  feOtpRequested(
    properties?: FeOtpRequestedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeOtpRequested(properties), options);
  }

  /**
   * fe_otp_resend_code
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_otp_resend_code)
   *
   * **Triggers:** when a user selects "resend code" on the OTP modal/page.
   *
   * **Description:** The user selects "resend code" without entering an OTP code. A new code will be sent to the existing phone number.
   *
   * Owner: rachel@skipify.com
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  feOtpResendCode(
    properties?: FeOtpResendCodeProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeOtpResendCode(properties), options);
  }

  /**
   * fe_otp_sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_otp_sent)
   *
   * **Triggers:** when the OTP /send call (where we request the OTP to be sent) comes back successful from the backend. 
   *
   *
   * **Description: A**n OTP code was sent to the user's number on file. The /send API call, where we request the OTP to be sent to the user, comes back successful from the backend, we can assume the OTP was successfully sent to the user.
   *
   * Owner: rachel@skipify.com
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  feOtpSent(
    properties?: FeOtpSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeOtpSent(properties), options);
  }

  /**
   * fe_otp_submitted
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_otp_submitted)
   *
   * **Triggers:** when a user completes entering an OTP code.
   *
   *
   * **Description:** The user attempted to complete an OTP by entering the OTP code they were sent. Authentication success is not yet determined. This event is merged with the following historical events:
   *
   * 1\. express_fe_otp_submitted = flow_type:button_checkout
   *
   * 2\. guestenrollment_fe_otp_submitted = flow_type:guest_enrollment
   *
   * 3\. guestredirect_fe_otp_submitted = flow_type:expedited_guest_checkout
   *
   * Owner: rachel@skipify.com
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  feOtpSubmitted(
    properties?: FeOtpSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeOtpSubmitted(properties), options);
  }

  /**
   * fe_otp_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_otp_success)
   *
   * **Triggers:** when a user enters the correct OTP code.** 
   *
   *  Description:** The user entered a valid OTP code and is authenticated. This event is merged with the following historical events: 1. guestenrollment_fe_otp_correct = flow_type:guest_enrollment 2. api_otp_success
   *
   * Owner: Jesus
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  feOtpSuccess(
    properties?: FeOtpSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeOtpSuccess(properties), options);
  }

  /**
   * fe_otp_verification_sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_otp_verification_sent)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  feOtpVerificationSent(
    options?: EventOptions,
  ) {
    return this.track(new FeOtpVerificationSent(), options);
  }

  /**
   * fe_passkey_add_request
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_passkey_add_request)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  fePasskeyAddRequest(
    options?: EventOptions,
  ) {
    return this.track(new FePasskeyAddRequest(), options);
  }

  /**
   * fe_passkey_added
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_passkey_added)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. status)
   * @param options Amplitude event options.
   */
  fePasskeyAdded(
    properties: FePasskeyAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FePasskeyAdded(properties), options);
  }

  /**
   * fe_passkey_auth_retry
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_passkey_auth_retry)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  fePasskeyAuthRetry(
    options?: EventOptions,
  ) {
    return this.track(new FePasskeyAuthRetry(), options);
  }

  /**
   * fe_passkey_deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_passkey_deleted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. status)
   * @param options Amplitude event options.
   */
  fePasskeyDeleted(
    properties: FePasskeyDeletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FePasskeyDeleted(properties), options);
  }

  /**
   * fe_payment_method_added_fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_payment_method_added_fail)
   *
   * **Triggers:** when the tokenizer returns an error adding the card to the user’s wallet.
   *
   * **Description:** A user attempted to add a new payment method to their wallet and it failed. This event will be triggered when any payment method type (manual or card linked card) is not successfully added to their wallet. Ex. tried to add a CL card but stepup was unsuccessful
   *
   * Owner: spandhana
   *
   * @param properties The event's properties (e.g. card_issuer_name)
   * @param options Amplitude event options.
   */
  fePaymentMethodAddedFail(
    properties?: FePaymentMethodAddedFailProperties,
    options?: EventOptions,
  ) {
    return this.track(new FePaymentMethodAddedFail(properties), options);
  }

  /**
   * fe_payment_method_added_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_payment_method_added_success)
   *
   * **Triggers:** when a user successfully adds a new payment method to their wallet.
   *
   * **Description:** A user added a new payment method and it was successfully stored in their wallet to be used in later transactions. This event will be triggered when any payment method type (manual or card linked card) is successfully added.
   *
   * Owner: spandhana
   *
   * @param properties The event's properties (e.g. card_issuer_name)
   * @param options Amplitude event options.
   */
  fePaymentMethodAddedSuccess(
    properties?: FePaymentMethodAddedSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new FePaymentMethodAddedSuccess(properties), options);
  }

  /**
   * fe_payment_method_deleted_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_payment_method_deleted_success)
   *
   * **Triggers:** when a user successfully deletes an existing payment method from their wallet. 
   *
   *
   * **Description:** A user removes a payment method from their wallet. This event will be triggered when any payment method type (manual or card-linked card) is successfully deleted. This event will be used to calculate how many active cards we have stored in skipify.
   *
   * Owner: rachel@skipify.com
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  fePaymentMethodDeletedSuccess(
    properties?: FePaymentMethodDeletedSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new FePaymentMethodDeletedSuccess(properties), options);
  }

  /**
   * fe_payment_started
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_payment_started)
   *
   * **Triggers:** when a user clicks "Pay" on the Review and Pay page.
   *
   *
   * **Description:** The user has attempted to complete checkout by clicking "pay" on the Review and Pay page, but the success of the payment has not yet been determined. This event is merged with the following historical events: 
   *
   * 1\. express_fe_payment_submitted = flow_type:button_checkout 2. guestredirect_fe_payment_submitted = flow_type:expedited_guest_checkout 
   *
   * 3\. paybylink_fe_payment_submitted = flow_type:paylink_checkout
   *
   * Owner: rachel@skipify.com
   *
   * @param properties The event's properties (e.g. card_issuer_name)
   * @param options Amplitude event options.
   */
  fePaymentStarted(
    properties?: FePaymentStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FePaymentStarted(properties), options);
  }

  /**
   * fe_pre_otp_phone_requested
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_pre_otp_phone_requested)
   *
   * Event to track when Skipify needs to collect the phone number of a shopper (when we display the phone number input field to the shopper) before a one-time passcode (OTP) can be sent.
   *
   * @param properties The event's properties (e.g. device_id)
   * @param options Amplitude event options.
   */
  fePreOtpPhoneRequested(
    properties?: FePreOtpPhoneRequestedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FePreOtpPhoneRequested(properties), options);
  }

  /**
   * fe_pre_otp_phone_submit
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_pre_otp_phone_submit)
   *
   * **Triggers:** when the user is able to click "continue" on the signup/login page.
   *
   * **Description:** In order to retrieve card metadata for a user, amex requires both email and phone to match the CM account before user can authenticate to view
   *
   * Owner: Jesus
   *
   * @param properties The event's properties (e.g. card_linking_issuer)
   * @param options Amplitude event options.
   */
  fePreOtpPhoneSubmit(
    properties?: FePreOtpPhoneSubmitProperties,
    options?: EventOptions,
  ) {
    return this.track(new FePreOtpPhoneSubmit(properties), options);
  }

  /**
   * fe_purchasecomplete_pageview
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_purchasecomplete_pageview)
   *
   * Triggers when a shopper lands on the Purchase Complete page
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  fePurchasecompletePageview(
    properties?: FePurchasecompletePageviewProperties,
    options?: EventOptions,
  ) {
    return this.track(new FePurchasecompletePageview(properties), options);
  }

  /**
   * fe_request_right_to_know
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_request_right_to_know)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. flow_type)
   * @param options Amplitude event options.
   */
  feRequestRightToKnow(
    properties?: FeRequestRightToKnowProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeRequestRightToKnow(properties), options);
  }

  /**
   * fe_request_to_unenroll
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_request_to_unenroll)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. flow_type)
   * @param options Amplitude event options.
   */
  feRequestToUnenroll(
    properties?: FeRequestToUnenrollProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeRequestToUnenroll(properties), options);
  }

  /**
   * fe_reviewpay_pageview
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_reviewpay_pageview)
   *
   * **Triggers:** When the user lands on the review and pay page in the checkout flow.
   *
   * **Description:** this event tells us each time a user makes it to the review and pay page in checkout. It helps us understabd how users behave throughout the checkout flow and identify checkout dropoff points. This event is added to match existing events on the button and EGC flows that indicate the user landed on the review and pay page.
   *
   * merged with the following historical events: - express_fe_viewed_completed_wallet
   *
   * * guestredirect_fe_viewed_completed_wallet
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  feReviewpayPageview(
    properties?: FeReviewpayPageviewProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeReviewpayPageview(properties), options);
  }

  /**
   * fe_session_expired
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_session_expired)
   *
   * This event is triggered when a user's session expires.
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  feSessionExpired(
    properties?: FeSessionExpiredProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeSessionExpired(properties), options);
  }

  /**
   * fe_set_default_address
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_set_default_address)
   *
   * This event is triggered when a user sets a default address in the checkout process
   *
   * @param properties The event's properties (e.g. address_id)
   * @param options Amplitude event options.
   */
  feSetDefaultAddress(
    properties: FeSetDefaultAddressProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeSetDefaultAddress(properties), options);
  }

  /**
   * fe_set_default_auth
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_set_default_auth)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. old_type)
   * @param options Amplitude event options.
   */
  feSetDefaultAuth(
    properties?: FeSetDefaultAuthProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeSetDefaultAuth(properties), options);
  }

  /**
   * fe_set_default_payment_method
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_set_default_payment_method)
   *
   * This event is triggered when a user sets a default payment method for their account.
   *
   * @param properties The event's properties (e.g. card_id)
   * @param options Amplitude event options.
   */
  feSetDefaultPaymentMethod(
    properties: FeSetDefaultPaymentMethodProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeSetDefaultPaymentMethod(properties), options);
  }

  /**
   * fe_survey_opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_survey_opened)
   *
   * This event tracks when the customer engagement survey is displayed to a user. It is triggered after an order is completed in all use cases.
   *
   * @param properties The event's properties (e.g. flow_type)
   * @param options Amplitude event options.
   */
  feSurveyOpened(
    properties?: FeSurveyOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeSurveyOpened(properties), options);
  }

  /**
   * fe_survey_response
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_survey_response)
   *
   * This event captures the response data from the user surveys conducted on the platform. It includes information such as the survey question, the user's response, and any additional metadata associated with the survey.
   *
   * @param properties The event's properties (e.g. flow_type)
   * @param options Amplitude event options.
   */
  feSurveyResponse(
    properties?: FeSurveyResponseProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeSurveyResponse(properties), options);
  }

  /**
   * fe_view_addresses
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_view_addresses)
   *
   * This event tracks when a user views their saved addresses during the checkout process
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  feViewAddresses(
    properties?: FeViewAddressesProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeViewAddresses(properties), options);
  }

  /**
   * fe_view_payment_methods
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/fe_view_payment_methods)
   *
   * The shopper opens the accordion on the account page to view their payment methods
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  feViewPaymentMethods(
    properties?: FeViewPaymentMethodsProperties,
    options?: EventOptions,
  ) {
    return this.track(new FeViewPaymentMethods(properties), options);
  }

  /**
   * paybylink_initiated
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/paybylink_initiated)
   *
   * **Triggers:** when the user lands on the initial Paylink page. **Description:** This event tracks when the user lands on the inital page of a paylink request. Indicated the start of the paylink checkout flow.
   *
   * Owner: rachel@skipify.com
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  paybylinkInitiated(
    properties?: PaybylinkInitiatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaybylinkInitiated(properties), options);
  }

  /**
   * unenrollment_fe_success
   *
   * [View in Tracking Plan](https://data.amplitude.com/skipify/Checkout/events/main/latest/unenrollment_fe_success)
   *
   * **Description:** The customer has successfully unenrolled and their account has been deleted.
   *
   * Owner: andrew.chapello@fisglobal.com
   *
   * @param properties The event's properties (e.g. customer_id)
   * @param options Amplitude event options.
   */
  unenrollmentFeSuccess(
    properties?: UnenrollmentFeSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new UnenrollmentFeSuccess(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
