import { ComponentOptions } from "../@types/util";
import UncheckedRadioIcon from "@icons/UncheckedRadioIcon";
import React from "react";
import { fontWeights } from "../base/typography";
import CheckedRadioIcon from "@icons/CheckedRadioIcon";

const MuiRadio: ComponentOptions<"MuiRadio"> = {
  defaultProps: {
    disableRipple: true,
    icon: React.createElement(UncheckedRadioIcon),
    checkedIcon: React.createElement(CheckedRadioIcon),
    // sx: { height: "24px", width: "24px" },
  },
  styleOverrides: {
    root: ({ theme }) => ({
      marginRight: "8px",
      padding: "2px",
      marginLeft: "7px",
      "&.Mui-checked": {
        "& + .MuiFormControlLabel-label": {
          fontWeight: fontWeights.bold,
        },
      },
      "&.Mui-disabled": {
        ".MuiSvgIcon-root": {
          ".checked-radio-center": {
            fill: theme.palette.text.disabled,
            stroke: theme.palette.text.disabled,
          },
          ".checked-radio-border": {
            stroke: theme.palette.text.disabled,
          },
          ".checked-radio-background, .unchecked-radio-background": {
            fill: theme.palette.background.light,
          },
        },
      },
      "&:hover": {
        background: theme.palette.brand.light,
        borderRadius: "50%",
      },
      "&.Mui-focusVisible": {
        background: theme.palette.brand.light,
        height: "24px",
        width: "24px",
        borderRadius: "50%",
      },
    }),
  },
};

const components = { MuiRadio };
export default components;
