import useStepupHandlerStore from "@stores/stepupHandlerStore";
import { FunctionComponent, useEffect } from "react";
import ExternalContent from "./ExternalContent";
import { useMediaQuery, useTheme } from "@mui/material";
import ampli from "@services/skipifyEvents";
import getEnv from "@utils/getEnv";

/**
 * Checks if the given input is a valid URL.
 *
 * @param url A URL object or a string that is potentially a URL.
 * @returns Valid status.
 */
function isValidUrl(url: string | URL): boolean {
  if (url instanceof URL) {
    return true;
  }

  try {
    new URL(url);
  } catch (_) {
    return false;
  }

  return true;
}

/**
 * Logic for displaying and hiding the third party stepup. Adds in a listener for the callback to set the success state and close the iframe.
 */
const StepupListener: FunctionComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { iframeHidden, setIframeHidden, setSuccess, setStepupComplete, stepupSrc } = useStepupHandlerStore();

  useEffect(() => {
    if (!window) {
      return;
    }

    /**
     * Listens for a close iframe event from the Skipify stepup callback site.
     *
     * @param ev Message event from any source.
     */
    function messageListener(ev: MessageEvent) {
      if (ev.origin !== getEnv().STEPUP_CALLBACK_ORIGIN) {
        return;
      }

      const { name, payload } = ev.data;

      if (name !== "@skipify/close-iframe") {
        return;
      }

      const { success } = payload;

      setIframeHidden(true);
      setSuccess(success);
      setStepupComplete(true);
      success ? ampli.track("fe_cl_issuer_challenge_success") : ampli.track("fe_cl_issuer_challenge_fail");
    }

    window.addEventListener("message", messageListener);

    if (getEnv().DEBUG === "true") {
      console.log("Stepup callback listener registered on window");
    }

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, [setIframeHidden, setSuccess, setStepupComplete]);

  const canShow = isValidUrl(stepupSrc);
  const visible = canShow && !iframeHidden;
  const maxWidth = isMobile ? false : "xs";

  // TODO: verify height
  return (
    <ExternalContent
      hidden={!visible}
      src={stepupSrc.toString()}
      maxWidth={maxWidth}
      width="100%"
      height="450"
      onClose={() => {
        setSuccess(false);
        setStepupComplete(true);
        setIframeHidden(true);
        ampli.track("fe_cl_issuer_challenge_exited");
        ampli.setIssuer(undefined);
      }}
    />
  );
};

export default StepupListener;
