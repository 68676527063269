import { FunctionComponent } from "react";
import Confetti from "react-confetti";

type ConfettiProps = {
  width: number;
  height: number;
};
const SkipifyConfetti: FunctionComponent<ConfettiProps> = ({ width, height }) => {
  const colors = ["#01ead3", "#ACF775", "#A882DD"];

  return (
    <Confetti
      style={{ zIndex: 1205 }}
      numberOfPieces={500}
      tweenDuration={2000}
      colors={colors}
      drawShape={(ctx) => {
        ctx.beginPath();
        ctx.rect(0, 0, 5, 9);
        ctx.fill();
        ctx.stroke();
        ctx.closePath();
      }}
      recycle={false}
      width={width}
      height={height}
      gravity={0.1}
    />
  );
};

export default SkipifyConfetti;
