import { SvgIcon } from "@mui/material";

const UncheckedCheckboxIcon = () => {
  return (
    <SvgIcon>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="4" width="15" height="15" rx="3.5" fill="#FEFEFE" />
        <rect x="4" y="4" width="15" height="15" rx="3.5" stroke="#E0E0E0" />
      </svg>
    </SvgIcon>
  );
};

export default UncheckedCheckboxIcon;
