"use client";

import { AxiosError } from "axios";
import Router from "next/router";
import emitter from "@utils/eventEmitter";

const UNAUTHORIZED_CODE = 401;

const interceptUnauthorized = (error: AxiosError) => {
  // Paylink and embedded checkout handlers
  if (
    window.location.pathname.includes("/embed") ||
    (window.location.pathname.includes("/paylink") && !window.location.pathname.includes("/v2/paylink"))
  ) {
    if (!window.location.pathname.includes("/login") && error.response?.status === UNAUTHORIZED_CODE) {
      let basePath = Router.pathname.substring(0, Router.pathname.indexOf("[oid]"));
      if (Router.pathname.includes("[mid]") && typeof Router.query.mid === "string") {
        basePath = basePath.replace("[mid]", Router.query.mid);
      }
      if (window.location.pathname.includes("/paylink")) {
        // oid is required for paylink refresh
        Router.replace(`${basePath}${Router.query.oid}/?redirect=${Router.asPath}`);
      } else {
        Router.replace(`${basePath}?redirect=${Router.asPath}`);
      }
    }
  }

  if (
    (window.location.pathname.includes("/dashboard") || // Interceptor for Shopper Dashboard
      window.location.pathname.includes("/v2/paylink")) && // Intercept app route paylink
    !window.location.pathname.includes("/login") &&
    !window.location.pathname.includes("/otp") &&
    !window.location.pathname.includes("/passkey") &&
    error.response?.status === UNAUTHORIZED_CODE
  ) {
    try {
      emitter.emit("unauthorizedAPIResponse", error);
    } catch (err) {
      console.error("[ShopperDashboard] Error emitting redirect event", err);
    }
  }
  return Promise.reject(error);
};

export default interceptUnauthorized;
