import fp from "./fingerprint";

import { getPriceFromAdjustablePrice } from "@utils/orderUtils";
import { Unit } from "skipify-types";
import Dinero from "dinero.js";
import { FlowTypes } from "@constants/amplitude";
import { Order } from "@models/order";
import { Event, EventPropertiesMap, EventType, IdentifyProperties } from "@models/skipifyEvents";

const formatPrices = (price?: Partial<Unit>) => {
  if (!price) return undefined;
  return Dinero({ amount: Number(price.value), currency: (price?.uom || "USD") as Dinero.Currency }).toRoundedUnit(2);
};

export class Pubsub {
  flowType?: FlowTypes = undefined;
  issuer?: string = undefined;
  deviceId?: string = undefined;
  customerId?: string = undefined;
  // Merchant properties
  merchant_id?: string = undefined;
  merchant_name?: string = undefined;
  merchant_industry?: string = undefined;
  parent_merchant_name?: string = undefined;
  // Order properties
  subtotal?: number;
  total?: number;
  isLoaded: boolean = false;
  sessionId?: number = undefined;

  setFlowType(flow: FlowTypes) {
    this.flowType = flow;
  }

  setIssuer(issuer?: string) {
    this.issuer = issuer;
  }

  setCustomerId(customerId: string) {
    this.customerId = customerId;
  }

  setMerchantProperties(merchantId?: string, merchantName?: string, industry?: string, parentMerchant?: string) {
    this.merchant_id = merchantId;
    this.merchant_name = merchantName;
    this.merchant_industry = industry;
    this.parent_merchant_name = parentMerchant;
  }

  getMerchantProperties() {
    return {
      merchant_id: this.merchant_id,
      merchant_name: this.merchant_name,
      merchant_industry: this.merchant_industry,
      parent_merchant_name: this.parent_merchant_name,
    };
  }

  setOrderProperties(order: Order) {
    const subtotal = formatPrices({
      value: getPriceFromAdjustablePrice(order?.pricing.subTotal),
      uom: order?.pricing.subTotal.amount.uom,
    });
    const total = formatPrices(order?.pricing.total);

    if (subtotal) this.subtotal = subtotal;
    if (total) this.total = total;
  }

  async loadWithFingerprint() {
    // this function assumes that any overrides are set safely by the caller, and does not check to see if you're in a dev environment.
    try {
      this.deviceId = await fp.getFingerprint();
    } catch (e) {
      console.warn("fingerprint client error on amplitude initialization", e);
    }

    console.warn("not implemented yet");
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(userId?: string, properties?: IdentifyProperties) {
    userId;
    properties;
    console.warn("not implemented yet");
  }

  private getRequiredEventProps() {
    return {
      ...this.getMerchantProperties(),
      total: this.total,
      subtotal: this.subtotal,
      card_linking_issuer: this.issuer,
      flow_type: this.flowType,
      device_id: this.deviceId,
      customer_id: this.customerId,
    };
  }

  track<T extends EventType>(type: Event<T>, event_properties?: EventPropertiesMap[T]) {
    type;
    event_properties;
    this.getRequiredEventProps();
    console.warn("not implemented yet");
  }

  trackLookupUser(email?: string, isPhoneRequired?: boolean) {
    email;
    isPhoneRequired;
    console.warn("not implemented yet");
  }

  refresh() {
    console.warn("not implemented yet");
  }

  async onExit() {
    console.warn("not implemented yet");
  }
}
