import axios from "axios";

import {
  CompleteOrderRequest,
  CompleteOrderResponse,
  Order,
  UpdateOrderRequest,
  UpdateOrderRequestV2,
} from "@models/order";
import transformRequestWithAuthorization from "./utils/transformRequestWithAuthorization";
import interceptUnauthorized from "./utils/interceptUnauthorized";
import axiosRetry from "@services/utils/axiosRetry";
import { addSentryInterceptors } from "./utils/interceptSentry";
import interceptWithErrorLogs from "@services/utils/interceptWithErrorLogs";
import interceptWithAmplitudeHeaders from "./utils/interceptWithAmplitudeHeaders";
import interceptWithFingerprint from "./utils/interceptWithFingerprint";
import getEnv from "@utils/getEnv";
import { mapUpdateOrderRequestToV2 } from "@utils/orderUtils";

const BASE_URL = getEnv().ORDERS_SERVICE_ROOT;

const orderApi = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  transformRequest: [transformRequestWithAuthorization],
});
addSentryInterceptors(orderApi);
orderApi.interceptors.response.use(undefined, interceptUnauthorized);
orderApi.interceptors.response.use(undefined, interceptWithErrorLogs);
orderApi.interceptors.request.use(interceptWithFingerprint);
orderApi.interceptors.request.use(interceptWithAmplitudeHeaders);

axiosRetry(orderApi);

export async function createOrder(merchantId: string, payload: unknown) {
  const res = await orderApi.post<Order>(`/${merchantId}`, payload, {
    headers: { "Content-Type": "application/json" },
  });
  return res.data;
}

export function claimOrder(orderId: string) {
  return orderApi.patch<void>(`/${orderId}/claim`);
}

export async function fetchOrder(id: string): Promise<Order> {
  const res = await orderApi.get<Order>(`/${id}`);
  return res.data;
}

export async function updateOrder(id: string, payload: UpdateOrderRequest) {
  const res = await orderApi.patch<Order>(`/${id}`, payload, {
    headers: { "Content-Type": "application/json" },
  });
  return res.data;
}

export async function completeOrder(id: string, payload: CompleteOrderRequest): Promise<CompleteOrderResponse> {
  const res = await orderApi.post<CompleteOrderResponse>(`/${id}/complete`, payload, {
    headers: { "Content-Type": "application/json" },
  });
  return res.data;
}

export async function reviewOrder(id: string): Promise<Order> {
  const res = await orderApi.patch<Order>(`/${id}/review`);
  return res.data;
}

export async function updateReviewOrder(
  id: string,
  payload: UpdateOrderRequest | UpdateOrderRequestV2,
  splitPayments: boolean,
): Promise<Order> {
  if (splitPayments) {
    payload = mapUpdateOrderRequestToV2(payload);
  }
  const res = await orderApi.patch<Order>(`/${id}/updateReview`, payload, {
    headers: { "Content-Type": "application/json" },
  });
  return res.data;
}

export async function deleteOrderItem(orderId: string, itemId: string): Promise<Order> {
  const res = await orderApi.delete<Order>(`/${orderId}/items/${encodeURIComponent(itemId)}`);
  return res.data;
}
