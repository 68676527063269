import { SvgIcon } from "@mui/material";

const UncheckedRadioIcon = () => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <rect x="4" y="4" width="15" height="15" rx="7.5" fill="#FEFEFE" className="unchecked-radio-background" />
        <rect x="4" y="4" width="15" height="15" rx="7.5" stroke="#E0E0E0" />
      </svg>
    </SvgIcon>
  );
};

export default UncheckedRadioIcon;
