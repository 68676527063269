import { ReactNode } from "react";
import { Grid } from "@mui/material";
import PaylinkBasicLayout from "./PaylinkBasicLayout";
import { Order } from "@models/order";

export type LayoutProps = {
  children?: ReactNode;
  order?: Order;
  header?: ReactNode;
  footer?: ReactNode;
};

// This Paylink layout layer passes down header and footer to Paylink basic layout, displays order summary/cart as appropriate (in header always for now)
export default function PaylinkLayout({ children, header, footer }: LayoutProps) {
  return (
    <PaylinkBasicLayout
      header={header}
      footer={footer}
      renderContent={(children) => (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}
    >
      {children}
    </PaylinkBasicLayout>
  );
}
