import fp from "./fingerprint";

import { getPriceFromAdjustablePrice } from "@utils/orderUtils";
import { Unit } from "skipify-types";
import Dinero from "dinero.js";

import { Types } from "@amplitude/analytics-browser";
import { FlowTypes } from "@constants/amplitude";
import { Ampli as Amplitude, Event, EventOptions, IdentifyProperties, PromiseResult, Result } from "ampli";
import { Order } from "@models/order";
import getEnv from "@utils/getEnv";

const formatPrices = (price?: Partial<Unit>) => {
  if (!price) return undefined;
  return Dinero({ amount: Number(price.value), currency: (price?.uom || "USD") as Dinero.Currency }).toRoundedUnit(2);
};

export type AmpliFingerprintLoadOptions = Types.BrowserOptions;

export class Ampli extends Amplitude {
  flowType?: FlowTypes = undefined;
  issuer?: string = undefined;
  deviceId?: string = undefined;
  customerId?: string = undefined;
  // Merchant properties
  merchant_id?: string = undefined;
  merchant_name?: string = undefined;
  merchant_industry?: string = undefined;
  parent_merchant_name?: string = undefined;
  // Order properties
  subtotal?: number;
  total?: number;

  setFlowType(flow: FlowTypes) {
    this.flowType = flow;
  }

  setIssuer(issuer?: string) {
    this.issuer = issuer;
  }

  setCustomerId(customerId: string) {
    this.customerId = customerId;
  }

  setMerchantProperties(merchantId?: string, merchantName?: string, industry?: string, parentMerchant?: string) {
    this.merchant_id = merchantId;
    this.merchant_name = merchantName;
    this.merchant_industry = industry;
    this.parent_merchant_name = parentMerchant;
  }

  getMerchantProperties() {
    return {
      merchant_id: this.merchant_id,
      merchant_name: this.merchant_name,
      merchant_industry: this.merchant_industry,
      parent_merchant_name: this.parent_merchant_name,
    };
  }

  setOrderProperties(order: Order) {
    const subtotal = formatPrices({
      value: getPriceFromAdjustablePrice(order?.pricing.subTotal),
      uom: order?.pricing.subTotal.amount.uom,
    });
    const total = formatPrices(order?.pricing.total);

    if (subtotal) this.subtotal = subtotal;
    if (total) this.total = total;
  }

  async loadWithFingerprint(configuration?: AmpliFingerprintLoadOptions) {
    // this function assumes that any overrides are set safely by the caller, and does not check to see if you're in a dev environment.
    try {
      this.deviceId = await fp.getFingerprint();
    } catch (e) {
      console.warn("fingerprint client error on amplitude initialization", e);
    }

    if (getEnv().AMPLITUDE_API_KEY) {
      this.load({
        client: {
          apiKey: getEnv().AMPLITUDE_API_KEY || "",
          configuration: { deviceId: this.deviceId, ...configuration },
        },
      });
    } else {
      console.error("ERROR: To use amplitude provide an amplitude key in getEnv().AMPLITUDE_API_KEY variable");
    }
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(userId?: string, properties?: IdentifyProperties, options?: Types.EventOptions) {
    return super.identify(userId, properties, { ...this.getMerchantProperties(), ...options });
  }

  private getRequiredEventProps() {
    return {
      ...this.getMerchantProperties(),
      total: this.total,
      subtotal: this.subtotal,
      card_linking_issuer: this.issuer,
      flow_type: this.flowType,
      device_id: this.deviceId,
      customer_id: this.customerId,
    };
  }

  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    event.event_properties = {
      ...this.getRequiredEventProps(),
      ...event.event_properties,
    };
    if (this.isLoaded) return super.track(event, options);
    return { promise: Promise.resolve() };
  }

  trackLookupUser(email?: string, isPhoneRequired?: boolean) {
    this.identify(email);
    if (isPhoneRequired !== undefined) {
      isPhoneRequired ? this.feCustomerNotRecognized() : this.feCustomerRecognized();
    }
  }

  refresh() {
    if (this.client?.getUserId()) {
      this.client?.setUserId(undefined);
      this.customerId = undefined;
    }
    this.client?.setSessionId(Date.now());
  }

  async onExit() {
    this.feCheckoutExited();
    await this.client?.flush().promise;
  }
}
