import * as Sentry from "@sentry/browser";
import { AxiosError, AxiosRequestConfig, AxiosResponse, AxiosInstance, AxiosStatic } from "axios";

type configWithTransaction = AxiosRequestConfig & { transaction?: Sentry.Transaction };

const getTransaction = ({ method, url }: AxiosRequestConfig) => {
  const existingTransaction = Sentry.getCurrentHub().getScope()?.getTransaction();

  if (existingTransaction && !existingTransaction.endTimestamp) {
    return existingTransaction;
  }
  // Create a transaction if one does not exist in order to work around
  // https://github.com/getsentry/sentry-javascript/issues/3169
  return Sentry.startTransaction({
    name: `API Request: ${method?.toUpperCase()} ${url}`,
    description: "request with interceptor",
  });
};

export const addTransaction = async (requestConfig: configWithTransaction) => {
  try {
    const transaction = getTransaction(requestConfig);
    Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(transaction));

    requestConfig.transaction = transaction;

    return requestConfig;
  } catch (err) {
    Sentry.captureException(err);
    return requestConfig;
  }
};
export const finishTransaction = (response: AxiosResponse | AxiosError) => {
  (response.config as configWithTransaction).transaction?.finish();
  return response;
};

export const finishTransactionOnError = (response: AxiosResponse | AxiosError) => {
  (response.config as configWithTransaction).transaction?.finish();
  throw response;
};

export const addSentryInterceptors = (axiosInstance: AxiosInstance | AxiosStatic) => {
  axiosInstance.interceptors.request.use(addTransaction);
  axiosInstance.interceptors.response.use(finishTransaction, finishTransactionOnError);
};
