import axios from "axios";

import {
  AuthorizeRequest,
  GuestRequest,
  LookupUserFullOptions,
  LookupUserFullRequest,
  LookupUserRequest,
  ReAuthorizeRequest,
  VerifyRequest,
} from "@models/api/apiRequests";
import { Claims } from "@models/claims";
import {
  ApiResponse,
  AuthorizeResponse,
  GuestResponse,
  LookupUserResponse,
  VerifyResponse,
} from "@models/api/apiResponses";
import axiosRetry from "@services/utils/axiosRetry";
import interceptWithErrorLogs from "@services/utils/interceptWithErrorLogs";
import interceptWithFingerprint from "@services/utils/interceptWithFingerprint";
import inMemoryStorage, { IN_MEMORY_STORAGE_KEYS } from "@utils/inMemoryStorage";
import getEnv from "@utils/getEnv";

import { addSentryInterceptors } from "./utils/interceptSentry";
import interceptWithAmplitudeHeaders from "./utils/interceptWithAmplitudeHeaders";

// TODO: move to configuration
const BASE_URL = getEnv().AUTH_ROOT || `${getEnv().API_ROOT}/v3/auth-service`;

const API_MODULE = "shoppers/v1";

const LOOKUP_ENDPOINT = "/lookup_user";
const LOOKUP_FULL_ENDPOINT = "/lookup_user_full";
const LOOKUP_BY_FINGERPRINT_ENDPOINT = "/lookup_by_fingerprint";
const ACCEPT_ENDPOINT = "/challenge/accept";
const REACCEPT_ENDPOINT = "/challenge/reaccept";
const COMPLETE_ENDPOINT = "/challenge/complete";

const authApi = axios.create({
  baseURL: `${BASE_URL}/${API_MODULE}`,
  withCredentials: true,
});

const baseAuthApi = axios.create({
  baseURL: `${BASE_URL}/v1`,
  withCredentials: true,
});

addSentryInterceptors(authApi);
authApi.interceptors.response.use(undefined, interceptWithErrorLogs);
authApi.interceptors.request.use(interceptWithFingerprint);
authApi.interceptors.request.use(interceptWithAmplitudeHeaders);

axiosRetry(authApi);

//see: https://skipify.atlassian.net/browse/PS-355
export async function lookupUser(
  merchantId?: string | undefined,
  email?: string | undefined,
  phone?: string,
  transactionId?: string,
  abortController?: AbortController,
  skipCardLinking?: boolean,
): Promise<ApiResponse<LookupUserResponse>> {
  const payload: LookupUserRequest = {
    email,
    phone,
    transactionId: transactionId,
    skipCardLinking,
  };

  const config = {
    signal: abortController ? abortController.signal : undefined,
    headers: {
      "Content-Type": "application/json",
      "x-merchant-id": merchantId ? merchantId : "",
    },
  };

  const response = await authApi.post<ApiResponse<LookupUserResponse>>(LOOKUP_ENDPOINT, payload, config);
  return response.data;
}

export async function lookupUserFull({
  merchantId,
  email,
  phone,
  transactionId,
  skipCardLinking,
  abortController,
}: LookupUserFullOptions) {
  const payload: LookupUserFullRequest = {
    email,
    phone,
    transactionId,
    skipCardLinking,
  };

  const config = {
    signal: abortController ? abortController.signal : undefined,
    headers: {
      "Content-Type": "application/json",
      "x-merchant-id": merchantId ? merchantId : "",
    },
  };
  const response = await authApi.post<ApiResponse<LookupUserResponse>>(LOOKUP_FULL_ENDPOINT, payload, config);
  return response.data;
}

export async function lookupByFingerprint(
  merchantId?: string | undefined,
  abortController?: AbortController,
): Promise<ApiResponse<LookupUserResponse>> {
  const config = {
    signal: abortController ? abortController.signal : undefined,
    headers: {
      "Content-Type": "application/json",
      "x-merchant-id": merchantId ? merchantId : "",
    },
  };

  const response = await authApi.post<ApiResponse<LookupUserResponse>>(LOOKUP_BY_FINGERPRINT_ENDPOINT, {}, config);
  return response.data;
}

//see: https://skipify.atlassian.net/browse/PS-356
export async function authorize(transactionId: string): Promise<ApiResponse<AuthorizeResponse>> {
  const payload: AuthorizeRequest = {
    transactionId: transactionId,
  };

  const response = await authApi.post<ApiResponse<AuthorizeResponse>>(ACCEPT_ENDPOINT, payload, {
    headers: { "Content-Type": "application/json" },
  });

  return response.data;
}

export async function guestSignUp(email: string, phone?: string): Promise<ApiResponse<GuestResponse>> {
  const payload: GuestRequest = {
    email,
    phone,
  };
  const response = await authApi.post<ApiResponse<GuestResponse>>("/guest", payload, {
    headers: { "Content-Type": "application/json" },
  });
  inMemoryStorage.setItem(IN_MEMORY_STORAGE_KEYS.SESSION, response.data.data?.jwt);
  return response.data;
}

//see https://skipify.atlassian.net/browse/PS-413
//not sure what's the difference with /authorize right now
//you are supposed to use this to request resend code
export async function reAuthorize(
  transactionId: string,
  alternativeId?: string,
): Promise<ApiResponse<AuthorizeResponse>> {
  const payload: ReAuthorizeRequest = {
    transactionId: transactionId,
    alternativeDestinationId: alternativeId,
  };

  const response = await authApi.post<ApiResponse<AuthorizeResponse>>(REACCEPT_ENDPOINT, payload, {
    headers: { "Content-Type": "application/json" },
  });
  return response.data;
}

// TODO: may need to be more generic
// see: https://skipify.atlassian.net/browse/PS-357
export async function verify(transactionId: string, otp: string, value?: string): Promise<ApiResponse<VerifyResponse>> {
  const payload: VerifyRequest = {
    transactionId: transactionId,
    otp,
    value,
  };
  const response = await authApi.post<ApiResponse<VerifyResponse>>(COMPLETE_ENDPOINT, payload, {
    headers: { "Content-Type": "application/json" },
  });

  // Put auth token into the in-memory storage
  const token = response.data.data?.jwt;
  inMemoryStorage.setItem(IN_MEMORY_STORAGE_KEYS.SESSION, token);

  return response.data;
}

export async function promoteGuest(): Promise<ApiResponse<LookupUserResponse>> {
  const token = inMemoryStorage.getItem(IN_MEMORY_STORAGE_KEYS.SESSION);
  const response = await authApi.post<ApiResponse<LookupUserResponse>>(
    "/guest/promote",
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
}

export async function getClaims(): Promise<ApiResponse<Claims>> {
  const token = inMemoryStorage.getItem(IN_MEMORY_STORAGE_KEYS.SESSION);
  const response = await baseAuthApi.get<ApiResponse<Claims>>("/validate", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}
