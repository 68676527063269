import { Event, EventPropertiesMap, EventType, IdentifyProperties } from "@models/skipifyEvents";
import { FlowTypes } from "@constants/amplitude";
import { Order } from "@models/order";
import { Ampli } from "./amplitude";
import { Pubsub } from "./pubsub";

export class SkipifyEvents {
  private amplitudeService?: Ampli;
  private pubSubService?: Pubsub;
  private isInitialized: boolean = false;

  async enableServices(useAmplitude: boolean, usePubSub: boolean) {
    this.isInitialized = false;

    try {
      if (useAmplitude) {
        this.amplitudeService = new Ampli();
      }
      if (usePubSub) {
        this.pubSubService = new Pubsub();
      }
      this.isInitialized = true;
    } catch (err) {
      // timeout or initialization failed
      console.error(err);
    }
  }

  get isLoaded(): boolean {
    if (!this.isInitialized) return false;
    if (this.amplitudeService) {
      return this.amplitudeService.isLoaded;
    }
    if (this.pubSubService) {
      return this.pubSubService.isLoaded;
    }
    return true;
  }

  get flowType(): FlowTypes | undefined {
    if (this.amplitudeService) {
      return this.amplitudeService.flowType;
    }
    if (this.pubSubService) {
      return this.pubSubService.flowType;
    }
  }
  set flowType(value: FlowTypes | undefined) {
    if (this.amplitudeService) {
      this.amplitudeService.flowType = value;
    }
    if (this.pubSubService) {
      this.pubSubService.flowType = value;
    }
  }

  getSessionId(): number | undefined {
    if (this.amplitudeService) {
      return this.amplitudeService.client.getSessionId();
    }
    if (this.pubSubService) {
      return this.pubSubService.sessionId;
    }
  }

  setSessionId(id: number) {
    if (this.amplitudeService) {
      return this.amplitudeService.client.setSessionId(id);
    }
    if (this.pubSubService) {
      return (this.pubSubService.sessionId = id);
    }
  }

  setFlowType(flow: FlowTypes) {
    if (this.amplitudeService) {
      this.amplitudeService.setFlowType(flow);
    }
    if (this.pubSubService) {
      this.pubSubService.setFlowType(flow);
    }
  }

  setIssuer(issuer?: string) {
    if (this.amplitudeService) {
      this.amplitudeService.setIssuer(issuer);
    }
    if (this.pubSubService) {
      this.pubSubService.setIssuer(issuer);
    }
  }

  setCustomerId(customerId: string) {
    if (this.amplitudeService) {
      this.amplitudeService.setCustomerId(customerId);
    }
    if (this.pubSubService) {
      this.pubSubService.setCustomerId(customerId);
    }
  }

  setMerchantProperties(merchantId?: string, merchantName?: string, industry?: string, parentMerchant?: string) {
    if (this.amplitudeService) {
      this.amplitudeService.setMerchantProperties(merchantId, merchantName, industry, parentMerchant);
    }
    if (this.pubSubService) {
      this.pubSubService.setMerchantProperties(merchantId, merchantName, industry, parentMerchant);
    }
  }

  getMerchantProperties() {
    if (this.amplitudeService) {
      return this.amplitudeService.getMerchantProperties();
    }
    if (this.pubSubService) {
      return this.pubSubService.getMerchantProperties();
    }
  }

  setOrderProperties(order: Order) {
    if (this.amplitudeService) {
      this.amplitudeService.setOrderProperties(order);
    }
    if (this.pubSubService) {
      this.pubSubService.setOrderProperties(order);
    }
  }

  async loadWithFingerprint() {
    if (this.amplitudeService) {
      this.amplitudeService.loadWithFingerprint();
    }
    if (this.pubSubService) {
      this.pubSubService.loadWithFingerprint();
    }
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   */
  identify(userId?: string, properties?: IdentifyProperties) {
    if (this.amplitudeService) {
      this.amplitudeService.identify(userId, properties);
    }
    if (this.pubSubService) {
      this.pubSubService.identify(userId, properties);
    }
  }

  track<T extends EventType>(type: T, event_properties?: EventPropertiesMap[T]) {
    const event: Event<T> = {
      event_type: type,
      event_properties,
    };
    if (this.amplitudeService) {
      this.amplitudeService.track(event);
    }
    if (this.pubSubService) {
      this.pubSubService.track(event);
    }
  }

  trackLookupUser(email?: string, isPhoneRequired?: boolean) {
    if (this.amplitudeService) {
      this.amplitudeService.trackLookupUser(email, isPhoneRequired);
    }
    if (this.pubSubService) {
      this.pubSubService.trackLookupUser(email, isPhoneRequired);
    }
  }

  refresh() {
    if (this.amplitudeService) {
      this.amplitudeService.refresh();
    }
    if (this.pubSubService) {
      this.pubSubService.refresh();
    }
  }

  async onExit() {
    if (this.amplitudeService) {
      await this.amplitudeService.onExit();
    }
    if (this.pubSubService) {
      await this.pubSubService.onExit();
    }
  }
}

const skipifyEvents = new SkipifyEvents();

export default skipifyEvents;
