import { Divider, Grid, Link, Stack } from "@mui/material";
import { PropsWithChildren } from "react";
import PoweredBySkipifyIcon from "@icons/PoweredBySkipifyIcon";

export interface FooterProps {
  dataTestid?: string;
  onExit?(): void;
  hideLogo?: boolean;
  // controls spacing for links inside shopper dashboard for screen sizes greater than medium
  dashboardDesktopLayout?: boolean;
  footerBlockTopMargin?: number;
  footerHeight?: number;
}

export default function Footer({
  dataTestid,
  hideLogo,
  dashboardDesktopLayout,
  children,
  footerHeight,
  footerBlockTopMargin = 2,
}: PropsWithChildren<FooterProps>) {
  return (
    <footer data-testid={dataTestid}>
      <Stack direction="column" height={footerHeight} pt={footerBlockTopMargin}>
        {children && (
          <Grid item xs={12} md={5}>
            {children}
          </Grid>
        )}
        <Divider sx={{ mx: -2 }} flexItem />

        <Grid item xs={12} my={1.5}>
          <Stack direction="row" justifyContent={hideLogo ? "flex-end" : "space-between"}>
            {!hideLogo && <PoweredBySkipifyIcon style={{ margin: "auto 0", height: "10px" }} />}
            <Stack
              direction="row"
              justifyContent="right"
              fontSize="10px"
              fontWeight="bold"
              mr={dashboardDesktopLayout ? 20 : 0}
            >
              <Link
                href="https://www.skipify.com/us/terms-and-conditions/"
                target="_blank"
                sx={{ textDecoration: "none" }}
                color="text.primary"
                fontSize="10"
              >
                Terms
              </Link>
              &nbsp;•&nbsp;
              <Link
                href="https://www.skipify.com/us/privacy-policy/"
                target="_blank"
                sx={{ textDecoration: "none" }}
                color="text.primary"
                fontSize="10"
              >
                Privacy
              </Link>
            </Stack>
          </Stack>
        </Grid>
      </Stack>
    </footer>
  );
}
