import { Close } from "@mui/icons-material";
import { Dialog, DialogProps, IconButton, Slide, SlideProps } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { FunctionComponent, IframeHTMLAttributes, Ref, forwardRef } from "react";

type BaseProps = IframeHTMLAttributes<HTMLIFrameElement>;

type ExternalContentProps = BaseProps &
  Pick<DialogProps, "maxWidth"> & {
    onClose?: () => void;
  };

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: SlideProps["children"];
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ExternalContent: FunctionComponent<ExternalContentProps> = ({ style, hidden, maxWidth, onClose, ...props }) => {
  const inset = maxWidth === false ? "auto 0 0" : undefined;
  const width = maxWidth === false ? "100%" : undefined;

  return (
    <Dialog
      open={!hidden}
      disableEscapeKeyDown
      maxWidth={maxWidth}
      sx={{ ...(inset !== undefined && { inset }) }}
      PaperProps={{
        sx: { m: 0, p: 0, ...(width !== undefined && { width, maxWidth: width, borderRadius: "20px 20px 0 0" }) },
      }}
      TransitionComponent={Transition}
    >
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
          disableRipple
        >
          <Close />
        </IconButton>
      ) : null}
      <iframe hidden={hidden} style={{ ...style, border: 0 }} {...props} />
    </Dialog>
  );
};

export default ExternalContent;
