import { ReactNode, useState } from "react";
import { Box, Container, Grid, Stack, useMediaQuery, Theme } from "@mui/material";
import { usePathname } from "next/navigation";

import SkipifyConfetti from "@components/Confetti/SkipifyConfetti";

import { BasicContent, BasicHeader, BasicFooter } from "../BasicLayout";

type BasicLayoutProps = {
  withPadding?: boolean;
  children?: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  renderContent?: (children?: ReactNode) => ReactNode;
};

const PaylinkBasicLayout = ({
  children,
  header = <BasicHeader />,
  footer = <BasicFooter />,
  renderContent = (children?: ReactNode) => <BasicContent>{children}</BasicContent>,
}: BasicLayoutProps) => {
  const isMobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const pathname = usePathname();
  const orderCompletePath = pathname?.includes("ordercomplete");

  const [confettiSize, setConefttiSize] = useState<{ width: number; height: number } | null>(null);

  const PBLDesktopScrollbarStyle = isMobileScreen
    ? ""
    : {
        scrollbarGutter: "stable both-edges",
        "&::-webkit-scrollbar": {
          width: "10px",
        },
        "&::-webkit-scrollbar-track": {
          background: "rgba(255, 255, 255, 0)",
          borderRadius: 2,
          border: "solid 2px white",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "rgba(0, 0, 0, 0.30)",
          borderRadius: 2,
          border: "solid 2px white",
        },
      };

  const containerStyles = {
    maxHeight: "100%",
    height: isMobileScreen ? "100%" : undefined,
    width: isMobileScreen ? "100%" : "500px",
    backgroundColor: "white",
    borderRadius: 2,
    padding: "0 24px",
    overflow: "auto",
    position: "relative" as const, // set container to relative so absolute modal can be positioned relative to it
    //todo: re-evaluate this in PBL 2.0
    ...PBLDesktopScrollbarStyle,
  };

  return (
    <div
      style={{
        backgroundColor: isMobileScreen ? "white" : "rgba(0, 0, 0, 0.60)",
        height: "100%",
        width: "100%",
        padding: isMobileScreen ? 0 : "44px 0px 44px 0px",
      }}
    >
      <Container
        ref={(r) => {
          if (!confettiSize && r) {
            setConefttiSize({
              height: r.getBoundingClientRect().height - 4,
              width: r.getBoundingClientRect().width - 4,
            });
          }
        }}
        sx={containerStyles}
        maxWidth="md"
        disableGutters
        data-testid="paylink-basic-layout"
        id="paybylink-container" //todo: re-evaluate this in PBL 2.0
      >
        {orderCompletePath && confettiSize && (
          <SkipifyConfetti height={confettiSize.height} width={confettiSize.width}></SkipifyConfetti>
        )}
        <Stack sx={{ height: "100%" }} justifyContent="space-between">
          <Stack>
            {header}
            <Grid container direction="row" justifyContent="space-between" spacing={0} wrap="nowrap">
              {renderContent(children)}
            </Grid>
          </Stack>
          <Stack
            sx={{
              bottom: 0,
              position: "sticky",
              left: 0,
              right: 0,
              background: "white",
              zIndex: (theme) => theme.zIndex.drawer - 50,
            }}
            direction="row"
            justifyContent="center"
          >
            <Box width="100%">{footer}</Box>
          </Stack>
        </Stack>
      </Container>
    </div>
  );
};

export default PaylinkBasicLayout;
