import { AxiosRequestConfig } from "axios";

import fp from "@services/fingerprint";

const DEVICE_ID_HEADER = "x-device-id";

const interceptWithFingerprint = async (requestConfig: AxiosRequestConfig) => {
  if (!requestConfig.headers) {
    requestConfig.headers = {};
  }

  // Allow real fpjs on stage and prod OR if forced specifically
  let visitorId;
  try {
    //the sdk auto handles session caching and race conditions, only 1 request will be made
    visitorId = await fp.getFingerprint();
  } catch (e) {
    console.error("[interceptWithFingerprint] failed with:", e);
  }

  if (visitorId) {
    requestConfig.headers[DEVICE_ID_HEADER] = visitorId;
  }

  return requestConfig;
};

export default interceptWithFingerprint;
