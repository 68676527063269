import { Badge, Stack, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import displayPrice from "@utils/displayPrice";
import { getPriceFromAdjustablePrice, getTotalItemsQuantity } from "@utils/orderUtils";
import { Order } from "@models/order";
import useSkipifyLayer from "@hooks/useSkipifyLayer";
import useMerchantConfigStore from "@stores/merchantConfigStore";

const Cart = ({ open, order }: CartProps) => {
  const skipifyLayer = useSkipifyLayer();
  const branding = useMerchantConfigStore((state) => state.branding);
  //note: badges are supposed to be using "merchant's brand color", this feature is not implemented yet
  return (
    <Badge
      badgeContent={getTotalItemsQuantity(order.items)}
      overlap="circular"
      sx={{
        marginTop: 1,
        marginRight: skipifyLayer ? 0 : 1,
        color: "text.primary",
        "& .MuiBadge-badge": {
          backgroundColor: branding.color || "brand.main",
          minWidth: "16px",
          height: "16px",
          fontSize: "11px",
          top: 0,
          paddingX: 0.6,
        },
      }}
    >
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Stack direction="row" justifyContent="center" alignItems="center">
          <ExpandMoreIcon
            data-testid={open ? "collapse-cart" : "open-cart"}
            fontSize="small"
            sx={{
              transitionDuration: "0.3s",
              transitionProperty: "transform",
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
          <ShoppingCartOutlinedIcon fontSize="small" />
        </Stack>
        <Typography variant="small" fontWeight="bold">
          {displayPrice(order.pricing.total?.value || getPriceFromAdjustablePrice(order.pricing.subTotal), {
            currency: order.pricing.total?.uom || order.pricing.subTotal.amount.uom,
          })}
        </Typography>
      </Stack>
    </Badge>
  );
};

export type CartProps = {
  open: boolean;
  order: Order;
};

export default Cart;
