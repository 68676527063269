/**
 * Shared fingerprint js client instance
 */
import { CacheLocation, FpjsClient } from "@fingerprintjs/fingerprintjs-pro-spa";
import { v4 as uuidv4 } from "uuid";

import { getLocalStorageValue, isLocalStorageAvailable } from "@hooks/useLocalStorage";
import { LOCALSTORAGE_SETTING_KEYS } from "@constants/localStorage";
import getEnv from "@utils/getEnv";

export type GetFingerprintOptions = {
  bypassCache?: boolean;
};

interface Fingerprint {
  getFingerprint: (opts?: GetFingerprintOptions) => Promise<string>;
}

const internalClient = {
  init: () => Promise.resolve(),
  generateFakeFingerprint: () => `fake${uuidv4().replace(/-/g, "")}`,
};

class FP implements Fingerprint {
  private cachedFingerprint = "";
  private timeoutMs = 2000;
  private client: FpjsClient | typeof internalClient;
  private forceFPJS = false;
  private deviceIdOverride?: string;

  // Fixing concurrency for the first request of the fingerprint
  private firstFPJSRequestPromise: Promise<unknown> | null = null;

  constructor() {
    this.client = internalClient;

    if (typeof window !== "undefined" && ["local", "dev", "stage"].includes(getEnv().ENV || "")) {
      this.deviceIdOverride = new URLSearchParams(window.location.search).get("forceDeviceId") || undefined;
    }

    // figuring out if there's any force flags to use real integration if not required
    if (isLocalStorageAvailable()) {
      this.forceFPJS = getLocalStorageValue<string>(LOCALSTORAGE_SETTING_KEYS.forceFingerprintjs, "") === "true";
    }

    // set timeout for actual fingerprint JS calls based on env variable or retain default of 10 seconds.
    const envTimeout = parseInt(getEnv().FINGERPRINT_TIMEOUT_MS || "");
    if (!isNaN(envTimeout)) {
      this.timeoutMs = envTimeout;
    }

    // Allow real fpjs on stage and prod OR if forced specifically
    if (["stage", "prod"].includes(getEnv().ENV || "") || this.forceFPJS) {
      this.client = new FpjsClient({
        loadOptions: {
          apiKey: `${getEnv().FPJS_API_KEY}`,
          endpoint: "https://fingerprint.skipify.com",
        },
        // Since we're operating in the iframe solely for now, inmem storage is the best option
        // In the future we can decide based on the currently selected experience
        cacheLocation: CacheLocation.Memory,
      });
    }
  }

  async getFingerprint(opts: GetFingerprintOptions = { bypassCache: false }): Promise<string> {
    const { bypassCache } = opts;

    if (window && window.preflightFP) {
      const preflight = await window.preflightFP;
      if (preflight !== null) {
        this.cachedFingerprint = preflight;
      }
    }

    if (!this.forceFPJS && this.deviceIdOverride) {
      this.cachedFingerprint = this.deviceIdOverride;
    }

    if (!bypassCache && this.cachedFingerprint !== "") {
      return this.cachedFingerprint;
    }

    try {
      await this.client.init();
    } catch (e) {
      console.warn("[fingerprint] client initialization failed:", e);
      if (this.client instanceof FpjsClient) {
        this.client = internalClient;
        return this.getFingerprint({ bypassCache });
      } else {
        console.error("[fingerprint] nowhere to fallback on device id:", e);
      }
    }

    // If we're operating with a FpJS client - try to get a fingerprint
    if (this.client instanceof FpjsClient) {
      try {
        if (this.firstFPJSRequestPromise) {
          await this.firstFPJSRequestPromise;
        }

        // Put a staggering promise
        this.firstFPJSRequestPromise = new Promise(() => null);

        const res = await this.client.getVisitorData(
          {
            timeout: this.timeoutMs,
          },
          bypassCache,
        );
        this.cachedFingerprint = res.visitorId;
      } catch (e) {
        console.error("[fingerprint] Failed to get fingerprint js response", e);

        // If FPJS failed - fallback on internal client and reiterate
        this.client = internalClient;
        return this.getFingerprint({ bypassCache });
      } finally {
        this.firstFPJSRequestPromise = Promise.resolve();
      }
    } else {
      this.cachedFingerprint = this.client.generateFakeFingerprint();
    }

    return this.cachedFingerprint;
  }
}

const singleton = new FP();
export default singleton;
