import { SvgIcon } from "@mui/material";

const CheckedRadioIcon = () => {
  return (
    <SvgIcon>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="4" y="4" width="15" height="15" rx="7.5" fill="#E2F7F2" className="checked-radio-background" />
        <rect x="4" y="4" width="15" height="15" rx="7.5" stroke="#1F7F79" className="checked-radio-border" />
        <circle cx="11.5" cy="11.5" r="3" fill="#1F7F79" className="checked-radio-center" />
      </svg>
    </SvgIcon>
  );
};

export default CheckedRadioIcon;
